import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import './index.scss';
import { ReactComponent as UploadFile } from '@components/Icons/chat-upload-file.svg';
import { ReactComponent as UploadMedia } from '@components/Icons/chat-upload-img.svg';
import { ReactComponent as SendButton } from '@components/Icons/chat-send-button.svg';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@store/configureStore";
import ChatService from "@service/API/Chat";
import { Collection, File, Message, Room } from "@models/chat.model";
import Picker from 'emoji-picker-react';
import { ReactComponent as Emoji } from "@components/Icons/emoji.svg";
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import ChatUploader from '@components/AppChat/components/Uploader';
import { CHAT_FILE_EXTENSION, CHAT_MEDIA_EXTENSION, MAXIMUM_ROOM_MEMBERS } from 'src/configs';
import { useMediaQuery } from "react-responsive";
import { BREAK_POINT } from "@models/common.model";
import { setError } from '@store/State';
import { stripHtmlTag } from "src/helper";
import { useNavigate } from 'react-router-dom';
import { clearRoomIds } from '@store/Chat';
import StorageService from '@service/Storage';

interface Props {
  setLoadMore: (isLoadMore: boolean) => void;
  room: Room;
  roomId: string;
  file: File;
  emptyFile: (payload: { documents: Array<any>, medias: Array<any> }) => void;
  setFile: (payload: {
    id: string,
    url: string,
    type: string,
    name?: string
  }[]) => void;
  onTextMessageChange: (scrollHeight: number) => void;
  isClose?: boolean;
}

const ChatSender: FC<Props> = ({ ...props }) => {
  const { setLoadMore, setFile, onTextMessageChange, isClose } = props;
  const textareaRef = useRef<HTMLTextAreaElement | any>();
  const emojiPickerRef = useRef<HTMLDivElement>(null);
  const { room, roomId, file, emptyFile } = props;
  const currentUser = useSelector((state: RootState) => state.UserReducer.userInfo);
  const isDesktop = useMediaQuery({ minWidth: BREAK_POINT.xl });
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [emojiPickerVisible, setEmojiPickerVisible] = useState<boolean>(false);

  useOnClickOutside(emojiPickerRef, setEmojiPickerVisible);

  const isReceiverOnline = useMemo(() => room.onlineMembers.length === MAXIMUM_ROOM_MEMBERS, [room]);
  const hasFile = useMemo(() => !!(file && (file.medias.length || file.documents.length)), [file]);

  const TEXTAREA_MAX_HEIGHT = 86;

  const handleResizeTextarea = () => {
    textareaRef.current.style.height = 'auto';
    textareaRef.current.style.maxHeight = `${TEXTAREA_MAX_HEIGHT}px`;
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    onTextMessageChange(textareaRef.current.scrollHeight);
  };

  const clearTextarea = () => {
    textareaRef.current.value = null;
  };

  const onEmojiClick = (event: any, emojiObject: any) => {
    textareaRef.current.value += emojiObject.emoji;
    textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
  };

  const handleVisibleEmojiPicker = () => {
    setEmojiPickerVisible(emojiPickerVisible => !emojiPickerVisible);
  };

  const onTextareaChange = () => {
    handleResizeTextarea();
  };

  const isInvalidAttachmentQuantity = useMemo(() => {
    const MAX_DOCUMENT_QUANTITY = 1;
    const MAX_VIDEO_QUANTITY = 1;
    const MAX_IMAGE_QUANTITY = 10;
    const videos = [];
    const images = [];
    if (file.medias) {
      // @ts-ignore
      // eslint-disable-next-line no-restricted-syntax
      for (const media of file.medias) {
        if (CHAT_MEDIA_EXTENSION.split(",").slice(0, 5).includes(`.${media.url.split(".").pop()}` as string)) {
          images.push(media);
        }

        if (CHAT_MEDIA_EXTENSION.split(",").slice(5, CHAT_MEDIA_EXTENSION.length).includes(`.${  media.url.split(".").pop()}` as string)) {
          videos.push(media);
        }
      }
    }

    return file.documents.length > MAX_DOCUMENT_QUANTITY || images.length > MAX_IMAGE_QUANTITY || videos.length > MAX_VIDEO_QUANTITY;
  }, [file]);

  const sendMessage = async () => {
    if (!((textareaRef.current.value || "").trim().length)) {
      textareaRef.current.value = "";
    }
    if (!((textareaRef.current.value || "").trim()) && !hasFile) return;
    if (isInvalidAttachmentQuantity) return dispatch(setError({ message: "C007" }));
    if (!window.navigator.onLine) {
      dispatch(clearRoomIds([]));
      navigate("/network-error");
      return;
    }
    textareaRef.current?.focus();
    const roomIndex = await ChatService.getDocumentIndex(Collection.ROOMS, "roomId", roomId);
    const message: Message = {
      messageId: new Date().getTime(),
      token: StorageService.getUserToken(),
      content: stripHtmlTag(textareaRef.current.value),
      senderId: currentUser?.id as number,
      receiverId: room.memberIds.find(memberId => memberId !== currentUser?.id) as number,
      isMyProduct: currentUser?.id === room.productCreatedBy,
      roomId,
      file,
      productTitle: room.roomTitle,
      createdAt: new Date().getTime(),
      shouldSendNotice: !isReceiverOnline
    };
    clearTextarea();
    emptyFile({ documents: [], medias: [] });
    handleResizeTextarea();
    setEmojiPickerVisible(false);
    setLoadMore(false);
    Promise.all([await ChatService.createMessage(message), await ChatService.updateRoom(roomIndex as string)]);
    if (!isReceiverOnline) {
      const receiverId = room.members.find(member => member.id !== currentUser?.id)?.id;
      await ChatService.increaseUnreadMessage(roomIndex as string, receiverId as number);
    }
  };

  useEffect(() => {
    const handleSubmit = (event: any) => {
      const keyCode = event.which || event.keyCode;
      if (keyCode === 13 && !event.shiftKey) {
        event.preventDefault();
        sendMessage();
      }
    };
    if (isDesktop) {
      window.addEventListener("keypress", handleSubmit);
    }

    return () => window.removeEventListener("keypress", handleSubmit);
  }, [room, file, textareaRef.current]);

  return (
    <>
      <div className="ui-chat-sender__border" />
      <div className="ui-chat-sender" style={isClose ? { pointerEvents: 'none' } : {}}>
        <div className="ui-chat-sender__attachments">
          <div className="ui-chat-sender__file">
            <ChatUploader
              file={file}
              setFile={setFile}
              type="all"
              name={ `file-${roomId}` }
              icon={<UploadFile />}
              accept={`${CHAT_FILE_EXTENSION  }, ${  CHAT_MEDIA_EXTENSION}`}
              multiple
            />
          </div>
          <div className="ui-chat-sender__image">
            <ChatUploader
              file={file}
              setFile={setFile}
              type="media"
              name={ `media-${roomId}` }
              icon={<UploadMedia />}
              accept={CHAT_MEDIA_EXTENSION}
              multiple
            />
          </div>
        </div>
        <div className="ui-chat-sender__input">
        <textarea ref={textareaRef} cols={20} rows={1} placeholder="メッセージをご記入"
                  onChange={onTextareaChange}></textarea>
          <div className="ui-chat-sender__emoji-icon" onClick={handleVisibleEmojiPicker}>
            <Emoji />
          </div>
          {
            emojiPickerVisible && (
              <div className="ui-chat-sender__emoji-picker" ref={emojiPickerRef}>
                <Picker onEmojiClick={onEmojiClick} preload disableSearchBar />
              </div>
            )
          }
        </div>
        <div className="ui-chat-sender__send" onClick={sendMessage}>
          <SendButton/>
        </div>
      </div>
    </>
  );
};

export default ChatSender;
