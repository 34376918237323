import React, { FC, useEffect, useRef, useState } from "react";
import "./index.scss";
import { setAppLoading, setError } from "@store/State";
import ChatService from "@service/API/Chat";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@store/configureStore";
import { ChatFile } from "@store/Chat";
import { v4 as uuidv4 } from "uuid";
import { CHAT_FILE_EXTENSION, CHAT_MEDIA_EXTENSION } from "src/configs";
import { File } from "@models/chat.model";
import { MessageType } from "src/models/common.model";
import useOnMessage from "src/hooks/useOnMessage";

interface Props {
  icon: any;
  name: string;
  accept: string;
  maxSize?: number;
  multiple?: boolean;
  type: "media" | "document" | "all";
  setFile: (
    payload: {
      id: string;
      url: string;
      type: string;
      name?: string;
    }[]
  ) => void;
  file: File;
}

const ChatUploader: FC<Props> = ({ ...props }) => {
  const { icon, name, accept, multiple, type, setFile } = props;
  const dispatch = useDispatch<AppDispatch>();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const isValidFiles = (files: FileList) => {
    const MAX_DOCUMENT_QUANTITY = 1;
    const MAX_VIDEO_QUANTITY = 1;
    const MAX_IMAGE_QUANTITY = 10;
    const MAX_IMAGE_FILE_SIZE = 5;
    const MAX_VIDEO_SIZE = 100;
    const images: Array<any> = [];
    const videos: Array<any> = [];
    const documents: Array<any> = [];

    if (files.length) {
      // @ts-ignore
      // eslint-disable-next-line no-restricted-syntax
      for (const file of files) {
        if (
          !`${CHAT_FILE_EXTENSION},${CHAT_MEDIA_EXTENSION}`.includes(
            file.name.split(".").pop()
          )
        ) {
          if (inputRef.current) {
            (inputRef.current as any).value = null;
          }
          dispatch(
            setError({
              message: "C006",
              title: "選択されたファイルのタイプは現在サポートされていません。",
            })
          );
          return false;
        }

        if (CHAT_FILE_EXTENSION.includes(file.name.split(".").pop())) {
          documents.push(file);
        }

        if (
          CHAT_MEDIA_EXTENSION.split(",")
            .slice(0, 10)
            .includes(`.${file.name.split(".").pop()}` as string)
        ) {
          images.push(file);
        }

        if (
          CHAT_MEDIA_EXTENSION.split(",")
            .slice(10, CHAT_MEDIA_EXTENSION.length)
            .includes(`.${file.name.split(".").pop()}` as string)
        ) {
          videos.push(file);
        }
      }
    }

    if (
      documents.length > MAX_DOCUMENT_QUANTITY ||
      images.length > MAX_IMAGE_QUANTITY ||
      videos.length > MAX_VIDEO_QUANTITY
    ) {
      dispatch(setError({ message: "C007" }));
      return false;
    }

    if (documents.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const document of documents) {
        if (document.size / (1024 * 1024) > MAX_IMAGE_FILE_SIZE) {
          if (inputRef.current) {
            (inputRef.current as any).value = null;
          }
          dispatch(
            setError({
              message: "C005",
              title: "選択されたファイルサイズが大きすぎます。",
            })
          );
          return false;
        }
      }
    }

    if (images.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const image of images) {
        if (image.size / (1024 * 1024) > MAX_IMAGE_FILE_SIZE) {
          if (inputRef.current) {
            (inputRef.current as any).value = null;
          }
          dispatch(
            setError({
              message: "C005",
              title: "選択されたファイルサイズが大きすぎます。",
            })
          );
          return false;
        }
      }
    }

    if (videos.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const video of videos) {
        if (video.size / (1024 * 1024) > MAX_VIDEO_SIZE) {
          if (inputRef.current) {
            (inputRef.current as any).value = null;
          }
          dispatch(
            setError({
              message: "C005",
              title: "選択されたファイルサイズが大きすぎます。",
            })
          );
          return false;
        }
      }
    }
    return true;
  };

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!isValidFiles(files as FileList)) return;

    dispatch(setAppLoading(true));
    try {
      if (type === "media") {
        const response = await ChatService.uploadMedias(files);
        const transformedFiles = response.data.result.map((item: string) => ({
          id: uuidv4(),
          url: item,
          type: ChatFile.MEDIA,
        }));
        setFile(transformedFiles);
      }

      if (type === "document") {
        const response = await ChatService.uploadDocuments(files);
        const result: Array<{ fileName: string; link: string }> = [
          response.data.result,
        ];
        const transformedFiles = result.map(
          (item: { fileName: string; link: string }) => ({
            id: uuidv4(),
            url: item.link,
            type: ChatFile.DOCUMENT,
            name: item.fileName,
          })
        );
        setFile(transformedFiles);
      }

      if (type === "all") {
        const medias: Array<any> = [];
        const documents: Array<any> = [];
        // @ts-ignore
        // eslint-disable-next-line no-restricted-syntax
        for (const file of files) {
          if (CHAT_FILE_EXTENSION.includes(file.name.split(".").pop())) {
            documents.push(file);
          }

          if (CHAT_MEDIA_EXTENSION.includes(file.name.split(".").pop())) {
            medias.push(file);
          }
        }

        if (medias.length) {
          const mediaResponse = await ChatService.uploadMedias(medias);
          const transformedMediaFiles = mediaResponse.data.result.map(
            (item: string) => ({
              id: uuidv4(),
              url: item,
              type: ChatFile.MEDIA,
            })
          );
          setFile(transformedMediaFiles);
        }

        if (documents.length) {
          const documentResponse = await ChatService.uploadDocuments(documents);
          const result: Array<{ fileName: string; link: string }> = [
            documentResponse.data.result,
          ];
          const transformedDocumentFiles = result.map(
            (item: { fileName: string; link: string }) => ({
              id: uuidv4(),
              url: item.link,
              type: ChatFile.DOCUMENT,
              name: item.fileName,
            })
          );
          setFile(transformedDocumentFiles);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setAppLoading(false));
      if (inputRef.current) {
        (inputRef.current as any).value = null;
      }
    }
  };

  useEffect(() => {
    inputRef.current = document.querySelector(`#${name}`);
  }, []);

    const handleMessageFromApps = (ev:any) => {
      if (!ev || !ev.data) {
        return;
      }
      try {
        const message = JSON.parse(ev.data);
        switch (message.type) {
          case MessageType.LOADING: {
            dispatch(setAppLoading(message.data));
            break;
          }
          case MessageType.ATTACHMENT: {
            if ( props.type !== 'all') break;
            const fileName = message.data.fileName as string || 'document.pdf';
            const fileExtend = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
            setFile([{
                id: uuidv4(),
                url: message.data.link,
                type: ['HEIC', 'JPG', 'PNG', 'JPEG', 'GIF', 'BMP', 'MP4', 'WMV', 'WEBM', 'MKV', '3GP', 'FLV', 'MOV'].includes(fileExtend.toUpperCase()) ? ChatFile.MEDIA : ChatFile.DOCUMENT,
                name: message.data.fileName,
              }]);
            break;
          }
          case MessageType.ERROR: {
            if (message.data) {
              dispatch(setError(message.data || {}));
            }
            break;
          }
          case MessageType.GET_CAMERA_PERMISSIONS: {
            if ( props.type === 'all') break;
                inputRef.current?.click();
                inputRef.current?.blur();
            break;
          }
          default:
            break;
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    };
    useOnMessage(handleMessageFromApps);
    

  
  function sendMessage(type: String) {
    try {
      const message = {
        type: "FILE_ATTACHMENT",
        data: type === 'all' ? 'gallery' : 'video',
      };
      (window as any).ReactNativeWebView.postMessage(JSON.stringify(message));
    } catch (e) {
      console.log(e);
    }
  }
  function onSelectMedia(type: string) {
    if ((window as any).ReactNativeWebView) {
      sendMessage(type);
      return;
    }
    (inputRef.current as any).click();
  }

  return (
    <>
      <input
        ref={inputRef}
        hidden
        type="file"
        accept={accept}
        id={name}
        onChange={onChange}
        multiple={multiple}
        readOnly

      />

      <div className="ui-chat-uploader" onClick={(e)=> {
          e.preventDefault();
          e.stopPropagation();
          setTimeout(()=> {
            // inputRef.current?.click();
            // inputRef.current?.blur();
            onSelectMedia(props.type);
          }, 100);
      }}>
        <label htmlFor={name}>
          <div className="ui-chat-uploader__icon">{icon}</div>
        </label>
      </div>
    </>
  );
};

export default ChatUploader;
