import React, { FC, memo, useEffect, useMemo, useRef, useState } from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@store/configureStore";
import AppNavigation from "@components/AppNavigation";
import ChatMessage from "@components/AppChat/components/Message";
import ChatSender from "@components/AppChat/components/Sender";
import {
  collection, doc, getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "src/firebase";
import { Collection, File, Message, Room } from "@models/chat.model";
import { ChatFile, clearRoomId } from "@store/Chat";
import ChatService from "@service/API/Chat";
import { FileType } from "@models/common.model";
import { ReactComponent as Hide } from "@components/Icons/arrow-down-3.svg";
import { ReactComponent as Show } from "@components/Icons/arrow-up-1.svg";
import { ReactComponent as Close } from "@components/Icons/close.svg";
import classNames from "classnames";
import ChatSkeleton from "@components/AppChat/components/Skeleton";
import ChatPreviewer from "@components/AppChat/components/Previewer";
import {
  CHAT_DEFAULT_CURRENT_PAGE,
  CHAT_MESSAGE_PER_PAGE,
  MAXIMUM_ROOM_MEMBERS,
} from "src/configs";
import useBeforeUnload from "src/hooks/useBeforeUnload";
import { ProductItem, ProductTransactionStatus } from "@models/product.model";
import ProductService from "src/services/API/Product";
import { isEmpty } from "lodash";
import AppModal from "src/components/AppModal";
import { useTranslation } from "react-i18next";

interface Props {
  roomId: string;
  index: number;
  setVisibleShower: (visible: boolean) => void;
  setInitialSlide: (initialSlide: number) => void;
  setMedias: (medias: Array<{ id: string; url: string; type: string }>) => void;
  isImage: (url: string) => boolean;
}

const BoxChat: FC<Props> = memo(({ ...props }) => {
  const { t: translate } = useTranslation();
  const { roomIds, currentStatusRooms } = useSelector((state: RootState) => state.ChatReducer);
  const { roomId, index, setVisibleShower, setInitialSlide, setMedias } = props;
  const dispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector(
    (state: RootState) => state.UserReducer.userInfo
  );
  const selectedProduct = useSelector(
    (state: RootState) => state.ProductReducer.productDetail
  );
  const contentBodyRef = useRef<HTMLDivElement | null>(null);
  const dummyMessageRef = useRef<HTMLDivElement | null>(null);
  const dummyRef = useRef<HTMLDivElement | null>(null);
  const [room, setRoom] = useState<Room>({} as Room);
  const [loading, setLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<Array<Message>>([]);
  const [file, setFile] = useState<File>({ documents: [], medias: [] } as File);

  const [hide, setHide] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(
    CHAT_DEFAULT_CURRENT_PAGE
  );
  const [isLoadMore, setLoadMore] = useState<boolean>(false);
  const totalMessage = useRef<number>(0);
  const [scrollHeight, setScrollHeight] = useState<number>(42);

  const [closeChatBoxClickModalVisible, setCloseChatBoxClickModalVisible] = useState<boolean>(false);
  const isClose = [ProductTransactionStatus.CANCEL, ProductTransactionStatus.DONE].includes(currentStatusRooms?.find((f: any) => f.roomId === roomId)?.status!);

  const sasCode = useSelector(
    (state: RootState) => state.GlobalReducer.sasCode
  );

  const onClose = async (roomId: string) => {
    dispatch(clearRoomId(roomId));
    setLoadMore(false);
    setCurrentPage(CHAT_DEFAULT_CURRENT_PAGE);
    const roomIndex = await ChatService.getDocumentIndex(
      Collection.ROOMS,
      "roomId",
      roomId
    );
    await ChatService.leaveRoom(roomIndex as string, currentUser?.id as number);
    // handle when no message
    // if (!room.totalMessage) {
    //   await ChatService.deleteRoom(roomIndex as string);
    // }
  };

  const handleHide = () => {
    setHide((hide) => !hide);
  };

  const scrollToBottom = () => {
    dummyRef?.current?.scrollIntoView();
  };

  const receiverName = useMemo(() => {
    const user =
      room?.members?.filter(
        (member: { id: number; name: string }) => member.id !== currentUser?.id
      )?.[0] || {};
    if (Object.keys(user).length) {
      // eslint-disable-next-line no-irregular-whitespace
      return `${ user.companyName ? `${user.companyName}　${user.name}` : user.name }`;
    }
  }, [room]);
  const hasFile = useMemo(
    () => !!(file && (file.medias.length || file.documents.length)),
    [file]
  );
  const numberOfPages = useMemo(
    () =>
      Math.ceil((room?.totalMessage as number) / CHAT_MESSAGE_PER_PAGE) || 1,
    [room]
  );
  const lastVisibleMessageIndex = useMemo(
    () => messages.length - CHAT_MESSAGE_PER_PAGE * (currentPage - 1) - 1,
    [messages]
  );

  const handleLoadMore = (event: any): void => {
    if (event.target?.scrollTop === 0 && currentPage < numberOfPages) {
      setCurrentPage((currentPage) => currentPage + 1);
      setLoadMore(true);
      setTimeout(() => {
        dummyMessageRef.current?.scrollIntoView({ block: "end" });
      }, 500);
    }
  };

  const handleSetFile = (
    payload: { id: string; type: string; url: string; name?: string }[]
  ) => {
    payload.forEach((item) => {
      if (item.type === ChatFile.DOCUMENT) {
        setFile((prevState) => ({
          ...prevState,
          documents: [item, ...prevState.documents],
        }));
      }

      if (item.type === ChatFile.MEDIA) {
        setFile((prevState) => ({
          ...prevState,
          medias: [item, ...prevState.medias],
        }));
      }
    });
  };

  const handleDeleteFile = (payload: {
    type: "documents" | "medias";
    id: string;
  }) => {
    setFile({
      ...file,
      [payload.type]: file[payload.type].filter(
        (item) => item.id !== payload.id
      ),
    });
  };

  const handleEmptyFile = (payload: {
    documents: Array<any>;
    medias: Array<any>;
  }) => {
    setFile(payload);
  };

  const handledStyle = useMemo(() => {
    if (roomIds.length === 2) {
      return { right: index === 0 ? "432px" : "32px" };
    }

    if (roomIds.length === 3) {
      return { right: index === 0 ? "832px" : index === 1 ? "432px" : "32px" };
    }
  }, [roomIds, index]);

  const handleDeleteMessage = async (messageId: number) => {
    setLoadMore(true);
    const receiverId = room.members.find(
      (member) => member.id !== currentUser?.id
    )?.id;
    const isReceiverOnline = room.onlineMembers.length === MAXIMUM_ROOM_MEMBERS;
    const messageIndex = await ChatService.getDocumentIndex(
      Collection.MESSAGES,
      "messageId",
      messageId
    );
    const roomIndex = await ChatService.getDocumentIndex(
      Collection.ROOMS,
      "roomId",
      roomId
    );
    const unreadReceiverMessageQuantity = (room.unreadMessage as any)?.[
      receiverId as number
    ];
    await ChatService.deleteMessage(
      messageIndex as string,
      roomIndex as string,
      receiverId as number,
      isReceiverOnline,
      unreadReceiverMessageQuantity
    );
  };

  const handleTextMessageChange = (scrollHeight: number) => {
    if (scrollHeight > 86) return;
    setScrollHeight(scrollHeight);
  };

  // join or create room
  useEffect(() => {
    if (roomId && currentUser?.id) {
      (async () => {
        const isRoomExisted = await ChatService.isRoomExisted(roomId);
        if (isRoomExisted) {
          const roomIndex = await ChatService.getDocumentIndex(
            Collection.ROOMS,
            "roomId",
            roomId
          );
          await ChatService.joinRoom(
            roomIndex as string,
            currentUser?.id as number
          );
          await ChatService.resetUnreadMessage(
            roomIndex as string,
            currentUser?.id as number
          );
        } else if (!isEmpty(selectedProduct)) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          await ChatService.createRoom(addNewRoom(selectedProduct));
        } else {
          console.log(230, roomId);
          ProductService.getProduct(roomId.slice(0, 6))
            .then(async (res) => {
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              await ChatService.createRoom(addNewRoom(res.data.result, roomId));
            })
            .catch((err) => {
              onClose(roomId);
            });
        }
      })();
    }
  }, [roomId, currentUser?.id]);

  function addNewRoom(selectedProduct: ProductItem, roomId?: string) {
    let buyer: any = null;
    if (currentUser?.id as number === selectedProduct?.userId) {
      buyer = {
        id: selectedProduct?.buyerId as number,
        name: selectedProduct?.buyerName as string,
        companyName: selectedProduct?.buyerCompanyName as string,
      };
    } else {
      buyer = {
        id: currentUser?.id as number,
        name: currentUser?.name as string,
        companyName: currentUser?.companyName as string,
      };
    }
    const members = [
      buyer,
      {
        id: selectedProduct?.userId,
        name: selectedProduct?.sellerName,
        companyName: selectedProduct?.sellerCompany as string,
      },
    ];
    const room: Room = {
      roomId: roomId || `${selectedProduct.id}${currentUser?.id}${selectedProduct.userId}`,
      productId: selectedProduct.id as number,
      productCreatedBy: selectedProduct.userId,
      roomTitle: selectedProduct?.title,
      roomImage: selectedProduct?.productMedias?.filter(
        (media) => media.type === FileType.IMAGE
      )?.[0]?.url || "",
      members,
      memberIds: members.map((f: any) => f?.id),
      onlineMembers: [currentUser?.id as number],
      createdAt: new Date().getTime(),
      createdBy: currentUser?.id as number,
      unreadMessage: {
        [currentUser?.id as number]: 0,
        [selectedProduct?.userId as number]: 0,
      },
      totalMessage: 0,
      lastUpdatedAt: new Date().getTime(),
    };

    return room;
  }

  // snapshot messages by roomId
  useEffect(() => {
    const q = query(
      collection(db, Collection.MESSAGES),
      where("roomId", "==", roomId),
      orderBy("createdAt", "desc"),
      limit(currentPage * CHAT_MESSAGE_PER_PAGE)
    );
    const unSubscribe = onSnapshot(q, (querySnapshot) => {
      const messages = querySnapshot.docs.map((doc) =>
        doc.data()
      ) as Array<Message>;
      const sortedMessages = messages.sort((a, b) => a.createdAt - b.createdAt);
      // dispatch(setMessages(sortedMessages));
      setMessages(sortedMessages);
    });
    return () => unSubscribe();
  }, [roomId, currentPage]);

  // snapshot room by roomId
  useEffect(() => {
    setLoading(true);
    const q = query(
      collection(db, Collection.ROOMS),
      where("roomId", "==", roomId)
    );
    const unSubscribe = onSnapshot(q, (querySnapshot) => {
      setRoom(querySnapshot.docs.map((doc) => doc.data() as Room)[0]);
    });
    return () => unSubscribe();
  }, [roomId]);

  useEffect(() => {
    if (messages && messages.length && !isLoadMore) {
      setTimeout(() => {
        scrollToBottom();
      }, 300);
    }
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }, [messages, isLoadMore]);

  useEffect(() => {
    if (contentBodyRef.current) {
      contentBodyRef.current?.addEventListener("scroll", handleLoadMore);

      return () =>
        contentBodyRef.current?.removeEventListener("scroll", handleLoadMore);
    }
  }, [contentBodyRef.current, currentPage, numberOfPages]);

  useEffect(() => {
    setLoadMore(false);
    setCurrentPage(CHAT_DEFAULT_CURRENT_PAGE);
  }, [roomId]);

  useEffect(() => {
    const q = query(
      collection(db, Collection.MESSAGES),
      where("roomId", "==", roomId)
    );
    const unSubscribe = onSnapshot(q, (querySnapshot) => {
      totalMessage.current = querySnapshot.size;
      return () => unSubscribe();
    });
  }, []);

  // leave room when reload or close browser's tab
  useBeforeUnload(() => onClose(roomId));

  return roomId ? (
    <div
      className={classNames("ui-chat-box", hide && "hide")}
      style={handledStyle}
    >
      <AppNavigation title={room?.roomTitle} onClick={() => onClose(roomId)} />
      <div className="ui-chat-box__header">
        <div className="ui-chat-box__header-title">{room?.roomTitle}</div>
        <div className="ui-chat-box__header-actions">
          <div className="ui-chat-box__header-hide" onClick={handleHide}>
            {!hide ? <Hide /> : <Show />}
          </div>
          <div
            className="ui-chat-box__header-close"
            onClick={() => onClose(roomId)}
          >
            <Close />
          </div>
        </div>
      </div>
      {!!(room && Object.keys(room).length) && (
        <div className="ui-chat-box__content" style={{ height: `calc(100% - 76px - 64px - ${scrollHeight - 42}px)` }}>
          <div
            ref={(element) => {
              contentBodyRef.current = element;
            }}
            className={classNames(
              "ui-chat-box__content-body",
              hasFile && "hasFile"
            )}
          >
            {currentPage === numberOfPages && (
              <div className="ui-chat-box__content-header">
                <div className="ui-chat-box__content-header-img">
                  <div>
                    <img src={room?.roomImage.concat(sasCode as string) || ""} alt="" />
                  </div>
                </div>
                <div className="ui-chat-box__content-header-title">
                  {room?.roomTitle}
                </div>
              </div>
            )}
            <div className="ui-chat-box__content-messages">
              {!!(messages && messages.length) &&
                messages.map((message, index) => (
                  <div key={message.createdAt}>
                    <ChatMessage
                      isImage={props.isImage}
                      setMedias={setMedias}
                      setInitialSlide={setInitialSlide}
                      setVisibleShower={setVisibleShower}
                      message={message}
                      isLast={index === messages.length - 1}
                      deleteMessage={handleDeleteMessage}
                    />
                    {index === lastVisibleMessageIndex && currentPage >= 2 && (
                      <div className="dummyMessageRef" ref={dummyMessageRef} />
                    )}
                  </div>
                ))}
            </div>
            <div ref={dummyRef} />
          </div>
          <div onClick={() => {
              if (isClose) setCloseChatBoxClickModalVisible(true);
            }} className="ui-chat-box__actions" style={ isClose ? { background:'#CBCBCB', opacity: 0.6 } : {} }>
            {hasFile && (
              <ChatPreviewer isImage={props.isImage} deleteFile={handleDeleteFile} file={file} />
            )}
            <ChatSender
              onTextMessageChange={handleTextMessageChange}
              setFile={handleSetFile}
              setLoadMore={setLoadMore}
              room={room}
              roomId={roomId}
              file={file}
              emptyFile={handleEmptyFile}
              isClose={isClose}
            />
          </div>
        </div>
      )}
      {loading && <ChatSkeleton />}
      <AppModal
          visible={closeChatBoxClickModalVisible}
          title={translate('chat.close.title')}
          content={<p style={{ whiteSpace: 'pre', textAlign: 'start', paddingLeft: '30px' }}>{translate('chat.close.content')}</p>}
          okText="OK"
          onOk={() => setCloseChatBoxClickModalVisible(false)}
        />
    </div>
  ) : null;
});

export default BoxChat;
