import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getProvinces } from "@service/API/SignUp";
import { DeviceType } from "src/models/common.model";
import { NoticeItems } from "src/pages/notification/model";
import GlobalService from "src/services/API/Global";
import NotificationService from "src/services/API/Notification";

interface ZipCode {
  buildingName: string;
  district: string;
  province: string;
  street: string;
  zipCode: string | number;
}

export interface AppBannerSlider {
  imageUrl: string;
  externalUrl: string; //
  device: DeviceType;
  id: string;
}

interface GlobalState {
  provinces: string[];
  zipCodes: ZipCode[];
  sliders: AppBannerSlider[];
  banner: AppBannerSlider[];
  priceConfig: { key: string; value: number }[];
  systemConfig: { key: string; value: number }[];
  policyOrder?: any;
  currentPathname?: string[];
  totalUnreadNotice?: number;
  totalUnreadMyNotice?: number;
  noticeSystem?: NoticeItems;
  deviceToken?: string;
  isInCallingRoom?: boolean | undefined;
  isQuitMembership?: boolean;
  metaTitle?: string;
  convertVideoImage?: string;
  sasCode?: string;
  keywords?: string;
}

export const getListProvinces = createAsyncThunk(
  "global/provinces",
  // eslint-disable-next-line consistent-return
  async () => {
    const response = await getProvinces();
    if (response) {
      return response.data;
    }
  }
);

export const getListSlider = createAsyncThunk(
  "global/slider",
  // eslint-disable-next-line consistent-return
  async () => {
    const response = await GlobalService.getHeaderSlider();
    if (response) {
      return response.data;
    }
  }
);

export const getListBanner = createAsyncThunk(
  "global/banner",
  // eslint-disable-next-line consistent-return
  async () => {
    const response = await GlobalService.getBanner();
    if (response) {
      return response.data;
    }
  }
);

export const getPriceConfig = createAsyncThunk(
  "global/priceConfig",
  // eslint-disable-next-line consistent-return
  async () => {
    const response = await GlobalService.getPriceFilter();
    if (response) {
      return response.data;
    }
  }
);

export const getSystemConfig = createAsyncThunk(
  "global/systemConfig",
  // eslint-disable-next-line consistent-return
  async () => {
    const response = await GlobalService.getSystemConfig();
    if (response) {
      return response.data;
    }
  }
);

export const getPolicyOrderConfig = createAsyncThunk(
  "global/PolicyOrder",
  // eslint-disable-next-line consistent-return
  async () => {
    const response = await GlobalService.getPolicyOrderConfig();
    if (response) {
      return response.data;
    }
  }
);

export const getTotalUnreadNotice = createAsyncThunk(
  "global/TotalUnreadNotice",
  // eslint-disable-next-line consistent-return
  async () => {
    const response = await NotificationService.getListNotification(0, 20);
    if (response) {
      return response.data;
    }
  }
);

const initialState: GlobalState = {
  provinces: [],
  zipCodes: [],
  sliders: [],
  banner: [],
  priceConfig: [],
  systemConfig: [],
  currentPathname: [],
  totalUnreadNotice: 0,
  totalUnreadMyNotice: 0,
  noticeSystem: {},
  deviceToken: "",
  isInCallingRoom: undefined,
  isQuitMembership: false,
  metaTitle: '',
  convertVideoImage: '',
  sasCode: '',
  keywords:'マシプラ'
};
const slice = createSlice({
  name: "Global",
  initialState,
  reducers: {
    setPathname: (
      state,
      { payload: { pathname } }: { payload: { pathname: string } }
    ) => {
      state.currentPathname?.push(pathname);
    },
    setTotalUnreadNotice(state, action) {
      state.totalUnreadNotice = action.payload;
    },
    setTotalUnreadMyNotice(state, action) {
      state.totalUnreadMyNotice = action.payload;
    },
    setNoticeSystem(state, action) {
      state.noticeSystem = action.payload;
    },
    setDeviceToken(state, action: PayloadAction<string>) {
      state.deviceToken = action.payload;
    },
    setVideoCallStatus(state, action) {
      state.isInCallingRoom = action.payload;
    },
    clearCurrentPathname: (state) => {
      state.currentPathname = [];
    },
    setIsQuitMembership(state, action) {
      state.isQuitMembership = action.payload;
    },
    setMetaTitle(state, action) {
      state.metaTitle = action.payload;
    },
    setConvertVideoImage(state, action) {
      state.convertVideoImage = action.payload;
    },
    setSasCode(state, action) {
      state.sasCode = action.payload;
    },
    setKeywords(state, action) {
      state.keywords = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListProvinces.fulfilled, (state, action) => {
      state.provinces = action.payload.result;
    });

    builder.addCase(getListProvinces.rejected, (state) => {});

    builder.addCase(getListBanner.fulfilled, (state, action) => {
      state.banner = action.payload.result;
    });

    builder.addCase(getListSlider.fulfilled, (state, action) => {
      state.sliders = action.payload.result;
    });

    builder.addCase(getPriceConfig.fulfilled, (state, action) => {
      state.priceConfig = action.payload.result;
    });

    builder.addCase(getSystemConfig.fulfilled, (state, action) => {
      state.systemConfig = action.payload.result;
    });

    builder.addCase(getPolicyOrderConfig.fulfilled, (state, action) => {
      state.policyOrder = action.payload.result;
    });

    builder.addCase(getTotalUnreadNotice.fulfilled, (state, action) => {
      state.totalUnreadNotice = action.payload.result.totalUnread;
    });
  },
});

export const {
  setPathname,
  setTotalUnreadNotice,
  setNoticeSystem,
  setDeviceToken,
  setTotalUnreadMyNotice,
  setVideoCallStatus,
  clearCurrentPathname,
  setIsQuitMembership,
  setMetaTitle,
  setConvertVideoImage,
  setSasCode,
  setKeywords
} = slice.actions;

export default slice.reducer;
