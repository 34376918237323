import { Col, Row } from "antd";
import React, { FC, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "src/store/configureStore";
import ProductService from "src/services/API/Product";
import "./styles.scss";
import { Category, CategoryParent } from "@models/product.model";
import { setStatusFilter } from "src/store/Product-Filter";
import UserService from "src/services/API/User";
import AppModal from '@components/AppModal';
import { PATH_NAME } from 'src/configs';

interface Props {
  title?: string;
  route?: string;
  onClick?: () => void;
}

const AppFooter: FC<Props> = memo(({ ...props }) => {
  const { title, route, onClick } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const location = useLocation();

  const listCategory = useSelector(
    (state: RootState) => state.ProductReducer.listCategory
  );

  const systemConfig = useSelector(
    (state: RootState) => state.GlobalReducer.systemConfig
  );

  const [listMachine, setListMachine] = useState<Category[]>([]);
  const [listTool, setListTool] = useState<Category[]>([]);

  const [urlYoutube, setUrlYoutube] = useState<any>();
  const [urlAppleStore, setAppleStore] = useState<any>();
  const [urlGooglePlay, setUrlGooglePlay] = useState<any>();
  const [urlTermOfService, setUrlTermOfService] = useState<any>();
  const [urlUserGuide, setUrlUserGuide] = useState<any>();
  const [urlFAQ, setUrlFAQ] = useState<any>();
  const [urlCompanyInformation, setUrlCompanyInformation] = useState<any>();
  const [urlContact, setUrlContact] = useState<any>();
  const [urlPrivacyPolicy, setUrlPrivacyPolicy] = useState<any>();
  const [urlCompanyLaw, setUrlCompanyLaw] = useState<any>();
  const [confirmLogin, setConfirmLogin] = useState<boolean>(false);

  useEffect(() => {
    const listConvert = ProductService.getSecondCategory(listCategory);
    setListMachine(
      listConvert.filter((f) => f.parentId === CategoryParent.Machine)
    );
    setListTool(listConvert.filter((f) => f.parentId === CategoryParent.Tool));
  }, [listCategory]);

  useEffect(() => {
    setUrlYoutube(systemConfig.find((item) => item.key === "YoutubeChannel"));
    setAppleStore(systemConfig.find((item) => item.key === "AppleStore"));
    setUrlGooglePlay(systemConfig.find((item) => item.key === "GooglePlay"));
    setUrlTermOfService(
      systemConfig.find((item) => item.key === "TermOfService")
    );
    setUrlUserGuide(systemConfig.find((item) => item.key === "UserGuide"));
    setUrlFAQ(systemConfig.find((item) => item.key === "FAQ"));
    setUrlCompanyInformation(
      systemConfig.find((item) => item.key === "CompanyInformation")
    );
    setUrlContact(systemConfig.find((item) => item.key === "Contact"));
    setUrlPrivacyPolicy(
      systemConfig.find((item) => item.key === "PrivacyPolicy")
    );
    setUrlCompanyLaw(
      systemConfig.find((item) => item.key === "CompanyLaw")
    );
  }, [systemConfig]);

  function onClickCategory(category: Category) {
    navigate(`/dashboard?filter=true&categories=${category.id}`);

    if (location.pathname.includes("/dashboard")) {
      const scroll = document.getElementById("appLayout") as HTMLDivElement;

      if (scroll) {
        scroll.scrollTo({ top: 0, behavior: "smooth" });
      }
    }

  }

  return (
    <div className="ui-footer">
      <AppModal
        visible={confirmLogin}
        title="この先の機能はログインが必要となります。"
        content=""
        okText="ログイン"
        cancelText="閉じる"
        onOk={() => {
          navigate(`${ PATH_NAME.LOGIN }?returnUrl=/shop`);
          setConfirmLogin(false);
        }}
        onCancel={() => {
          setConfirmLogin(false);
        }}
      />
      <div className="footer-body">
        <Row className="row-footer">
          <Col span={7}>
            <div className="text-body-3 bold color-title">
              マシプラ You Tubeチャンネル公開中
            </div>
            <div className="text-body-4 color-title">
              マシプラに登録されている工作機械の動作状況を確認いただく事ができます。お探しの一品が見つかるかも。
            </div>
            <div>
              <a href={urlYoutube?.value} target="_blank" rel="noreferrer">
                <img
                  className="image-icon-youtube"
                  src="/assets/images/youtube.png"
                  alt=""
                />
              </a>
            </div>
          </Col>
          <Col span={5}>
            <div className="text-body-3 bold color-title">商品を購入する</div>
            <div className="text-body-4 bold color-title">機械</div>
            <div className="text-body-4 pointer color-title">
              {listMachine.map((category, index) => (
                <span
                  onClick={() => {
                    onClickCategory(category);
                  }}
                  key={category.id}
                >
                  {category.name}&nbsp;
                  {index <= listMachine.length - 2 && "|"}&nbsp;
                </span>
              ))}
            </div>
            <div className="text-body-4 bold color-title"> 工具</div>
            <div className="text-body-4 pointer color-title">
              {listTool.map((category, index) => (
                <span
                  onClick={() => {
                    onClickCategory(category);
                  }}
                  key={category.id}
                >
                  {category.name}&nbsp;
                  {index <= listTool.length - 2 && "|"}&nbsp;
                </span>
              ))}
            </div>
          </Col>
          <Col span={5}>
            <div className="text-body-3 bold color-title">商品を販売する</div>
            <div className="text-body-4 color-title pointer" onClick={()=> {
                if (UserService.isAuthenticated()) {
                    navigate('/shop');
                } else {
                  setConfirmLogin(true);
                }
            }}>商品販売</div>
            <div className="text-body-3 bold color-title">ご利用ガイド</div>
            <div className="text-body-4 color-title">
              <a
                className="color-title"
                href={urlUserGuide?.value}
                target="_blank"
                rel="noreferrer"
              >
                ご利用ガイド
              </a>
              <br />
              <a
                className="color-title"
                href={urlFAQ?.value}
                target="_blank"
                rel="noreferrer"
              >
                よくある質問
              </a>
              <br />
              <a
                className="color-title"
                href={urlTermOfService?.value}
                target="_blank"
                rel="noreferrer"
              >
                利用規約
              </a>
            </div>
          </Col>
          <Col span={7}>
            <div className="text-body-3 bold color-title">運営会社情報</div>

            <div className="text-body-4 color-title">
              <a
                href={urlCompanyInformation?.value}
                target="_blank"
                rel="noreferrer"
                className="color-title"
              >
                運営会社
              </a>
              <br />
              <a href={urlContact?.value} target="_blank" rel="noreferrer"  className="color-title">
                問合せ
              </a>
              <br />
              <a
                href={urlPrivacyPolicy?.value}
                target="_blank"
                rel="noreferrer"
                className="color-title"
              >
                有限会社原精工 プライバシーポリシー
              </a>
              <br />
              <a
                href={urlCompanyLaw?.value}
                target="_blank"
                rel="noreferrer"
                className="color-title"
              >
                特定商取引法に基づく表記
              </a>
            </div>

            <div className="img-store">
              <a href={urlAppleStore?.value} target="_blank" rel="noreferrer">
                <img src="/assets/images/apple_store.png" alt="" />
              </a>
              <a href={urlGooglePlay?.value} target="_blank" rel="noreferrer">
                <img src="/assets/images/google_play.png" alt="" />
              </a>
            </div>

            <div className="text-body-4 color-title">
              © 2022 有限会社原精工 All rights reserved.
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
});

export default AppFooter;

AppFooter.defaultProps = {};
