import React, { FC, memo, useMemo, useRef, useState } from "react";
import { Message } from "@models/chat.model";
import "./index.scss";
import { useSelector } from "react-redux";
import { RootState } from "@store/configureStore";
import classNames from "classnames";
import moment from "moment";
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from "src/configs";
import { handleDownloadFromUrl, urlify } from "src/helper";
import ChatShower from "@components/AppChat/components/Shower";
import { ReactComponent as Document } from "@components/Icons/document-download.svg";
import { Dropdown, Popover } from "antd";
import { ReactComponent as Action } from "@components/Icons/three-dot-vertical.svg";
import { ReactComponent as Delete } from "@components/Icons/bin.svg";
import AppModal from "@components/AppModal";
import { ReactComponent as Warning } from "@components/Icons/warning.svg";
import fileSaver, { saveAs } from "file-saver";

interface Props {
  message: Message;
  isLast?: boolean;
  deleteMessage?: (messageId: number) => void;
  setVisibleShower: (visible: boolean) => void;
  setInitialSlide: (initialSlide: number) => void;
  setMedias: (medias: Array<{ id: string; url: string; type: string }>) => void;
  isImage: (url: string) => boolean;
}

const ChatMessage: FC<Props> = memo(({ ...props }) => {
  const { senderId, createdAt, content, file, messageId } = props.message;
  const {
    isLast,
    deleteMessage,
    setVisibleShower,
    setInitialSlide,
    setMedias,
    isImage,
  } = props;
  const currentUser = useSelector(
    (state: RootState) => state.UserReducer.userInfo
  );
  const isMyMessage = senderId === currentUser?.id;
  const isToday = moment(createdAt).isSame(moment(), "day");
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [modalWarningVisible, setModalWarningVisible] =
    useState<boolean>(false);

  const sasCode = useSelector(
    (state: RootState) => state.GlobalReducer.sasCode
  );

  const showSlide = (initialSlide: number) => {
    setInitialSlide(initialSlide);
    setVisibleShower(true);
    setMedias(file?.medias);
  };

  const onVisibleChange = (visible: boolean) => setPopoverVisible(visible);

  const styledMedias = useMemo(
    () => ({
      width: file && file.medias.length < 3 ? "100%" : "70px",
      height: file && file.medias.length < 3 ? "100%" : "70px",
    }),
    [props.message]
  );

  const actionContent = () => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        // width: "max-content",
        boxShadow: "0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d",
        padding: "8px",
        borderRadius: "8px",
        background: "#fff"
      }}
      onClick={(e) => {
        e.stopPropagation();
        setModalWarningVisible(true);
        setPopoverVisible(false);
      }}
    >
      <div
        style={{ display: "flex" }}
        // onClick={(e) => {
        //   e.stopPropagation();
        //   setPopoverVisible(false);
        // }}
      >
        <Delete />
      </div>
      <div className="content__title" style={{ width: "max-content" }}>
        メッセージを取消
      </div>
    </div>
  );

  function handleDownload(document: any) {
    if ((window as any).ReactNativeWebView) {
      try {
        const message = {
          type: "DOWNLOAD",
          data: { ...document, url: document.url?.concat(sasCode as string) },
        };
        (window as any).ReactNativeWebView.postMessage(JSON.stringify(message));
      } catch (e) {
        return;
      }
      return;
    }
    saveAs(document.url?.concat(sasCode as string), document.name);
  }

  return (
    <>
      <AppModal
        visible={modalWarningVisible}
        icon={<Warning />}
        title="このメッセージを取り消します。よろしいですか？"
        content="取り消したメッセージは元に戻せません。"
        okText="はい"
        cancelText="いいえ"
        onCancel={() => setModalWarningVisible(false)}
        onOk={() => deleteMessage?.(messageId)}
      />
      <div className="ui-chat-message">
        <div
          className={classNames(
            "ui-chat-message__datetime",
            isMyMessage && "isMine"
          )}
        >
          {moment(createdAt).format(isToday ? TIME_FORMAT : DATE_TIME_FORMAT)}
        </div>
        <div
          className={classNames(
            "ui-chat-message__content",
            isMyMessage && "isMine"
          )}
        >
          {isMyMessage && (
            <div
              className="ui-chat-message__action"
              id={`ui-chat-message__action${messageId}`}
            >
              <Dropdown
                overlay={actionContent}
                trigger={["click"]}
              >
                <Action onClick={(e) => e.stopPropagation()} />
              </Dropdown>
            </div>
          )}
          <div>
            <div
              className="ui-chat-message__content-text"
              dangerouslySetInnerHTML={{ __html: urlify(content) }}
            />
            {!!(file && (file?.documents?.length || file?.medias?.length)) && (
              <div className="ui-chat-message__attachments">
                {!!file.documents.length &&
                  file.documents.map((document) => (
                    <div
                      className="ui-chat-message__document"
                      key={document.id}
                      onClick={() => {
                        handleDownload(document);
                      }}
                    >
                      <Document />
                      <span
                        // rel="noreferrer"
                        // target="_blank"
                        // href="https"
                        // download

                      >
                        {document.name}
                      </span>
                    </div>
                  ))}

                {!!file.medias.length && (
                  <div className="ui-chat-message__medias">
                    {file.medias.map((media, index) => (
                      <div
                        key={media.id}
                        className="ui-chat-message__media"
                        style={{ ...styledMedias }}
                        onClick={() => showSlide(index)}
                      >
                        {isImage(media.url) ? (
                          <img src={media.url.concat(sasCode as string)} alt="chat-media" />
                        ) : (
                          /* eslint-disable jsx-a11y/media-has-caption */
                          <div className="fake-video-container">
                            <div className="fake-click">&nbsp;</div>
                            <video
                              disablePictureInPicture
                              controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
                              src={`${media.url.concat(sasCode as string)}#t=0.1`}
                              onClick={() => showSlide(index)}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {!!(isLast && isMyMessage) && (
          <div className="ui-chat-message__status">送信済み</div>
        )}
      </div>
    </>
  );
});

export default ChatMessage;
