import React, { FC, useEffect, useState } from "react";
import { Col, Popover } from "antd";
import { useNavigate } from "react-router-dom";
import AppTag from "@components/AppTag";
import { ReactComponent as Action } from "@components/Icons/three-dots.svg";
import { ReactComponent as Edit } from "@components/Icons/edit.svg";
import { ReactComponent as Success } from "@components/Icons/success.svg";
import { ReactComponent as Publish } from "@components/Icons/public.svg";
import { ReactComponent as Private } from "@components/Icons/private.svg";
import { ReactComponent as Close } from "@components/Icons/close-small.svg";
import { ReactComponent as OnlineContactIcon } from "@components/Icons/online-contact-icon.svg";
import { ReactComponent as OnlineContactDisabledIcon } from "@components/Icons/online-contact-icon-disabled.svg";
import { ReactComponent as ConfirmContactIcon } from "@components/Icons/contact-icon.svg";
import { ReactComponent as FinishedShipmentIcon } from "@components/Icons/finished-shipment-icon.svg";
import { ReactComponent as FinishedShipmentDisabledIcon } from "@components/Icons/finished-shipment-icon-disabled.svg";
import { ReactComponent as Warning } from "@components/Icons/warning.svg";
import { ReactComponent as EstimatedDeliveryScheduleIcon } from "@components/Icons/estimated-delivery-schedule-icon.svg";

import {
  CANCEL_SELL_REASON,
  ContactType,
  ProductHistory,
  ProductTransactionStatus,
} from "src/models/product.model";
import { useTranslation } from "react-i18next";
import moment from "moment";
import NumberFormat from "react-number-format";
import { PATH_NAME } from "src/configs";
import AppModal from "src/components/AppModal";
import { useDispatch } from "react-redux";
import { Desktop, Mobile } from "src/layouts/break-point";
import AppModalCancelTransaction from "src/components/AppModalCancelTransaction";
import AppButton from "src/components/AppButton";
import { setRoomId } from "src/store/Chat";
import AppModalConfirmTransfer from "src/components/AppModalConfirmTransfer";
import { useMediaQuery } from "react-responsive";
import { BREAK_POINT } from "src/models/common.model";
import { clearProduct } from "src/store/Product";
import ProductService from "src/services/API/Product";
import { setAppLoading } from "src/store/State";
import { PreCallInfo } from "src/pages/visit-online/Preparation/model";
import { getIdError } from "src/helper";
import { injectHandleOk } from "src/App";

interface Props {
  item: ProductHistory;
  handleWhenClickStatus: (
    status: ProductTransactionStatus,
    item: ProductHistory
  ) => void;
  onReload?: () => void;
}

const SaleHistoryItem: FC<Props> = ({ ...props }) => {
  const { onReload } = props;
  const [item, setItem] = useState<ProductHistory>(props.item);

  let timeOutVisitOnline: NodeJS.Timeout;
  const { t: translate } = useTranslation();
  const [enableJoinContact, setEnableJoinContact] = useState(false);
  const [enableStartCall, setEnableStartCall] = useState(false);
  const [isConfirmTransfer, setIsConfirmTransfer] = useState(false);
  const [isFinishedShipment, setIsFinishedShipment] = useState(false);
  const [successModalConfirmVisible, setSuccessModalConfirmVisible] =
    useState(false);
  const [isBought, setIsBought] = useState<boolean>(false);
  const [isCancel, setIsCancel] = useState<boolean>(false);
  const [showModalDownload, setShowModalDownload] = useState<boolean>(false);
  const isApp = (window as any).ReactNativeWebView;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [isConfirmCancelTransaction, setIsConfirmCancelTransaction] =
    useState<boolean>(false);

  const [successModalCancelVisible, setSuccessModalCancelVisible] =
    useState<boolean>(false);

  const [visible, setVisible] = useState<boolean>(false);

  function editItem(item: ProductHistory) {
    if (item.buyerId) {
      dispatch(setAppLoading(true));
      ProductService.getOrderStatus(item.id)
        .then((res) => {
          const status = res.data.result;
          if (
            [
              ProductTransactionStatus.IN_PURCHASE,
              ProductTransactionStatus.IN_REQUEST,
              ProductTransactionStatus.IN_CONTACT,
              ProductTransactionStatus.IN_CONFIRM,
            ].some((item) => item === status)
          ) {
            dispatch(clearProduct());
            navigate(`${PATH_NAME.EDIT_PRODUCT}?id=${item.productId}`);
          } else {
            setIsBought(true);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          dispatch(setAppLoading(false));
        });
    } else {
      dispatch(clearProduct());
      navigate(`${PATH_NAME.EDIT_PRODUCT}?id=${item.productId}`);
    }
  }

  function checkDisableJoinContact() {
    if (item?.contactType === ContactType.MessageOnly) {
      setEnableJoinContact(true);
    } else if (item?.contactType === ContactType.OnlineTour) {
      setEnableJoinContact(false);
      const timeCall = moment(item?.contactTime).valueOf();
      const now = moment().valueOf();
      const diff = (timeCall - now) / (1000 * 60);
      if (diff > -30 && diff <= 30) {
        setEnableJoinContact(true);
        if (diff > 0) {
          if (timeOutVisitOnline) {
            clearTimeout(timeOutVisitOnline);
          }
          timeOutVisitOnline = setTimeout(() => {
            setEnableJoinContact(true);
          }, diff * 60 * 1000);
        } else {
          setEnableStartCall(true);
        }
      }
    }
  }

  useEffect(
    () => () => {
      if (timeOutVisitOnline) {
        clearTimeout(timeOutVisitOnline);
      }
    },
    []
  );

  useEffect(() => {
    checkDisableJoinContact();
  }, [item]);

  const handleClickInContact = () => {
    if (item?.contactType === ContactType.MessageOnly) {
      dispatch(setRoomId(`${item.productId}${item?.buyerId}${item.sellerId}`));
      setVisible(false);
    } else if (item?.contactType === ContactType.OnlineTour) {
      if (!enableJoinContact) {
        return;
      }
      if (isApp) {
        if (enableStartCall) {
          ProductService.getContactDetail(item.contactId)
            .then((res) => {
              const preCallInfo: PreCallInfo = res.data.result;
              const message = {
                type: "JOIN_CALL",
                data: preCallInfo,
              };
              (window as any).ReactNativeWebView.postMessage(
                JSON.stringify(message)
              );
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              dispatch(setAppLoading(false));
            });
        } else {
          navigate(
            `/visit-online/preparation?contactId=${item.contactId}&productId=${item.productId}`
          );
        }
      } else {
        setVisible(false);
        setShowModalDownload(true);
      }
    }
  };

  const handleClickFinishedShipment = () => {
    if (item?.scheduledDeliveryDate) {
      setVisible(false);
      setIsFinishedShipment(true);
    }
  };

  const scheduleButtonMobile = () => (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setVisible(false);
        setIsConfirmTransfer(true);
      }}
      className="ui-button-more-sale-history"
    >
      <div className="content__title">
        {item.expectedArrivalDate
          ? translate("sale.history.edit.estimated.delivery.schedule.button")
          : translate(
              "sale.history.setting.estimated.delivery.schedule.button"
            )}
      </div>
      <div style={{ display: "flex", marginLeft: "20px" }}>
        <EstimatedDeliveryScheduleIcon />
      </div>
    </div>
  );

  const scheduleButtonDesktop = () => (
    <div
      className="sale-ui-history-item-action"
      onClick={(e) => {
        e.stopPropagation();
        setVisible(false);
        setIsConfirmTransfer(true);
      }}
    >
      <AppButton buttontype="secondary">
        {item.expectedArrivalDate
          ? translate("sale.history.edit.estimated.delivery.schedule.button")
          : translate(
              "sale.history.setting.estimated.delivery.schedule.button"
            )}
      </AppButton>
    </div>
  );
  const editButtonMobile = () => (
    <div
      onClick={(e) => {
        e.stopPropagation();
        editItem(item);
        setVisible(false);
      }}
      className="ui-button-more-sale-history"
    >
      <div className="content__title">{translate("sale.history.edit")}</div>
      <div style={{ display: "flex", marginLeft: "20px" }}>
        <Edit />
      </div>
    </div>
  );

  const editButtonDesktop = () => (
    <div
      className="sale-ui-history-item-action"
      onClick={(e) => {
        e.stopPropagation();
        editItem(item);
        setVisible(false);
      }}
    >
      <AppButton buttontype="secondary">商品を編集する</AppButton>
    </div>
  );

  const cancelButtonMobile = () => (
    <div className="ui-button-more-sale-history">
      <div
        className="content__title"
        onClick={(e) => {
          e.stopPropagation();
          setVisible(false);
          setIsConfirmCancelTransaction(true);
        }}
      >
        {translate("sale.history.cancel")}
      </div>
      <div style={{ display: "flex", marginLeft: "20px" }}>
        <Close
          onClick={(e) => {
            e.stopPropagation();
            setVisible(false);
          }}
          style={{ border: "1px solid #333333", borderRadius: "50%" }}
        />
      </div>
    </div>
  );

  const cancelButtonDesktop = () => (
    <div
      className="sale-ui-history-item-action"
      onClick={(e) => {
        e.stopPropagation();
        setVisible(false);
        setIsConfirmCancelTransaction(true);
      }}
    >
      <AppButton buttontype="secondary">取引をキャンセル</AppButton>
    </div>
  );

  const confirmRequestButtonMobile = () => (
    <div
      className="ui-button-more-sale-history"
      onClick={(e) => {
        e.stopPropagation();
        navigate(`/my-page/sales-history/confirm-contact/${item?.contactId}`);
      }}
    >
      <div className="content__title">
        {translate("sale.history.in_request")}
      </div>
      <div style={{ display: "flex", marginLeft: "20px" }}>
        <ConfirmContactIcon />
      </div>
    </div>
  );

  const confirmRequestButtonDesktop = () => (
    <div
      className="sale-ui-history-item-action"
      onClick={(e) => {
        e.stopPropagation();
        navigate(`/my-page/sales-history/confirm-contact/${item?.contactId}`);
      }}
    >
      <AppButton buttontype="secondary">コンタクト日時を確認する</AppButton>
    </div>
  );

  const inContactButtonMobile = () => {
    if (item.contactType !== ContactType.LocalPreview) {
      return (
        <div
          className="ui-button-more-sale-history"
          onClick={(e) => {
            e.stopPropagation();
            handleClickInContact();
          }}
        >
          <div
            className="content__title"
            style={{ color: (!enableJoinContact && "#cacaca") || "#333333" }}
          >
            {item.contactType === ContactType.MessageOnly
              ? translate("sale.history.in_contact_message")
              : translate("sale.history.in_contact_video")}
          </div>
          <div style={{ display: "flex", marginLeft: "20px" }}>
            {(enableJoinContact && <OnlineContactIcon />) || (
              <OnlineContactDisabledIcon />
            )}
          </div>
        </div>
      );
    }
  };

  const inContactButtonDesktop = () => {
    if (item.contactType !== ContactType.LocalPreview) {
      return (
        <div
          className="sale-ui-history-item-action"
          onClick={(e) => {
            e.stopPropagation();
            handleClickInContact();
          }}
        >
          <AppButton buttontype="secondary" disabled={!enableJoinContact}>
            {item.contactType === ContactType.MessageOnly
              ? translate("sale.history.in_contact_message")
              : translate("sale.history.in_contact_video")}
          </AppButton>
        </div>
      );
    }
  };

  const finishedShipmentButtonMobile = () => (
    <div
      className="ui-button-more-sale-history"
      onClick={(e) => {
        e.stopPropagation();
        handleClickFinishedShipment();
      }}
    >
      <div
        className="content__title"
        style={{
          color: (!item.scheduledDeliveryDate && "#cacaca") || "#333333",
        }}
      >
        {translate("transaction.transfer.button")}
      </div>
      <div style={{ display: "flex", marginLeft: "20px" }}>
        {(item.scheduledDeliveryDate && <FinishedShipmentIcon />) || (
          <FinishedShipmentDisabledIcon />
        )}
      </div>
    </div>
  );

  const finishedShipmentButtonDesktop = () => (
    <div
      className="sale-ui-history-item-action"
      onClick={(e) => {
        e.stopPropagation();
        handleClickFinishedShipment();
      }}
    >
      <AppButton buttontype="secondary" disabled={!item.scheduledDeliveryDate}>
        {translate("transaction.transfer.button")}
      </AppButton>
    </div>
  );

  const renderAction = () => {
    const { status, buyerId } = item;
    const isMobile = useMediaQuery({ maxWidth: BREAK_POINT.lg - 1 });

    if (buyerId) {
      switch (status) {
        case ProductTransactionStatus.IN_PURCHASE:
        case ProductTransactionStatus.IN_CONFIRM:
          return isMobile ? (
            <>
              {editButtonMobile()}
              {cancelButtonMobile()}
            </>
          ) : (
            <>
              {editButtonDesktop()}
              {cancelButtonDesktop()}
            </>
          );
        case ProductTransactionStatus.IN_REQUEST:
          return isMobile ? (
            <>
              {confirmRequestButtonMobile()}
              {editButtonMobile()}
              {cancelButtonMobile()}
            </>
          ) : (
            <>
              {confirmRequestButtonDesktop()}
              {editButtonDesktop()}
              {cancelButtonDesktop()}
            </>
          );
        case ProductTransactionStatus.IN_CONTACT:
          return isMobile ? (
            <>
              {inContactButtonMobile()}
              {editButtonMobile()}
              {cancelButtonMobile()}
            </>
          ) : (
            <>
              {inContactButtonDesktop()}
              {editButtonDesktop()}
              {cancelButtonDesktop()}
            </>
          );
        case ProductTransactionStatus.PAYMENT_COMPLETE:
          return isMobile ? (
            <>
              {scheduleButtonMobile()}
              {finishedShipmentButtonMobile()}
            </>
          ) : (
            <>
              {scheduleButtonDesktop()}
              {finishedShipmentButtonDesktop()}
            </>
          );
        default:
          break;
      }
    } else {
      return isMobile ? <>{editButtonMobile()}</> : <>{editButtonDesktop()}</>;
    }
  };

  function getColor(status: ProductTransactionStatus) {
    switch (status) {
      case ProductTransactionStatus.IN_PURCHASE:
        return "#F04438";

      case ProductTransactionStatus.IN_REQUEST:
        return "#86E832";

      case ProductTransactionStatus.IN_CONTACT:
        return "#1976D2";

      case ProductTransactionStatus.IN_CONFIRM:
        return "#FFC122";

      case ProductTransactionStatus.IN_PAYMENT:
        return "#06B6C1";

      case ProductTransactionStatus.PAYMENT_COMPLETE:
        return "#7F56D9";

      case ProductTransactionStatus.IN_CHECKING:
        return "#FFA726";

      case ProductTransactionStatus.CONFIRM_RECEIVED:
        return "#B71289";

      case ProductTransactionStatus.DONE:
        return "#12B76A";

      case ProductTransactionStatus.CANCEL:
        return "#A3A2A2";

      case ProductTransactionStatus.UNPUBLISH:
        return "#1976D2";

      case ProductTransactionStatus.PUBLISH:
        return "#12B76A";

      default:
        return "#1976D2";
    }
  }

  function getBackground(status: ProductTransactionStatus) {
    switch (status) {
      case ProductTransactionStatus.IN_PURCHASE:
        return "#FFF0EF";

      case ProductTransactionStatus.IN_REQUEST:
        return "#E3F7CB";

      case ProductTransactionStatus.IN_CONTACT:
        return "#E9F5FF";

      case ProductTransactionStatus.IN_CONFIRM:
        return "#FFF9C6";

      case ProductTransactionStatus.IN_PAYMENT:
        return "#EBFFFF";

      case ProductTransactionStatus.PAYMENT_COMPLETE:
        return "#F4EBFF";

      case ProductTransactionStatus.IN_CHECKING:
        return "#FFEACC";

      case ProductTransactionStatus.CONFIRM_RECEIVED:
        return "#FFE9F9";

      case ProductTransactionStatus.DONE:
        return "#D1FADF";

      case ProductTransactionStatus.CANCEL:
        return "#EFF0F1";

      case ProductTransactionStatus.PUBLISH:
        return "#D1FADF";

      case ProductTransactionStatus.UNPUBLISH:
        return "#E9F5FF";

      default:
        return "#E9F5FF";
    }
  }

  function getIcon(status: ProductTransactionStatus) {
    if (status === ProductTransactionStatus.PUBLISH) {
      return <Publish />;
    }
    if (status === ProductTransactionStatus.UNPUBLISH) {
      return <Private />;
    }
    return undefined;
  }

  function updateDeliveryItem(
    scheduledDeliveryDate: string,
    expectedArrivalDate: string
  ) {
    setItem({
      ...item,
      scheduledDeliveryDate,
      expectedArrivalDate,
    });
  }

  const onVisibleChange = (visible: boolean) => setVisible(visible);

  const updateStatus = () => {
    dispatch(setAppLoading(true));
    ProductService.updateStatusTransfer({
      id: Number(item?.id),
      token: item?.orderToken as string
    })
      .then((res) => {
        if (res.data.result.id) {
          setItem((prev) => ({
            ...prev,
            status: res.data.result.status,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
        if (getIdError(err) === "M0127") {
          injectHandleOk(() => onReload?.());
        }
      })
      .finally(() => {
        dispatch(setAppLoading(false));
        setIsFinishedShipment(false);
      });
  };

  const textDownload = (
    <div style={{ marginTop: 4 }}>
      <p>{translate("prepration.download.guide1")}</p>
      <p>{translate("prepration.download.guide2")}</p>
      <p>{translate("prepration.download.guide3")}</p>
      <p>{translate("prepration.download.guide4")}</p>
    </div>
  );

  return (
    <>
      {showModalDownload && (
        <AppModal
          icon={<Warning />}
          visible={showModalDownload}
          title="この機能は開発中です。"
          content="ブラウザではオンライン見学をご利用できません。"
          okText="閉じる"
          // cancelText="閉じる"
          // onOk={() => {}}
          onOk={() => {
            setShowModalDownload(false);
          }}
        />
      )}

      {isFinishedShipment && (
        <AppModal
          icon={<Warning />}
          visible={true}
          title="搬出を完了します。よろしいでしょうか。"
          content="搬出完了通知が購入者に送信されます。"
          okText="はい"
          cancelText="いいえ"
          onOk={() => {
            updateStatus();
          }}
          onCancel={() => {
            setIsFinishedShipment(false);
          }}
        />
      )}

      {isBought && (
        <AppModal
          icon={<Warning />}
          visible={isBought}
          title="エラーが発生しました。"
          content="商品は既に購入確定されています。"
          okText="OK"
          onOk={() => {
            setIsBought(false);
            onReload?.();
          }}
        />
      )}

      {isCancel && (
        <AppModal
          icon={<Warning />}
          visible={isCancel}
          title="エラーが発生しました。"
          content="取引がキャンセルされました。"
          okText="閉じる"
          onOk={() => {
            setIsCancel(false);
          }}
        />
      )}

      {isConfirmTransfer && (
        <AppModalConfirmTransfer
          title="チャットで確定した搬出日と到着日を入力してください。"
          handleCancel={() => onReload}
          visible={isConfirmTransfer}
          onCancel={() => {
            setIsConfirmTransfer(false);
          }}
          onOk={() => {
            setIsConfirmTransfer(false);
            setSuccessModalConfirmVisible(true);
          }}
          updateDeliveryItem={updateDeliveryItem}
          value={{
            expectedArrivalDate: item?.expectedArrivalDate,
            scheduledDeliveryDate: item?.scheduledDeliveryDate,
          }}
          transactionId={Number(item?.id)}
        />
      )}

      <AppModal
        icon={<Success />}
        visible={successModalConfirmVisible}
        title="搬出予定日が購入者に通知されました。"
        content={moment().format("YYYY/MM/DD HH:mm")}
        okText="閉じる"
        onOk={() => {
          setSuccessModalConfirmVisible(false);
        }}
      />

      {isConfirmCancelTransaction && (
        <AppModalCancelTransaction
          handleCancel={onReload}
          visible={isConfirmCancelTransaction}
          title={translate("sale.history.transaction.warning.title")}
          content={translate("sale.history.transaction.warning.content")}
          okText={translate("sale.history.transaction.warning.button.ok")}
          cancelText={translate(
            "sale.history.transaction.warning.button.cancel"
          )}
          onCancel={() => {
            setIsConfirmCancelTransaction(false);
          }}
          onOk={() => {
            setIsConfirmCancelTransaction(false);
            setSuccessModalCancelVisible(true);
          }}
          transactionId={Number(item.id)}
          orderToken={item?.orderToken}
          cancelReasonList={CANCEL_SELL_REASON}
        />
      )}

      <AppModal
        icon={<Success />}
        visible={successModalCancelVisible}
        title={translate("sale.history.transaction.success.title")}
        content={translate("sale.history.transaction.success.content")}
        okText={translate("sale.history.transaction.success.button.close")}
        onOk={() => {
          setSuccessModalCancelVisible(false);
          onReload?.();
        }}
      />
      <Col xs={24} md={24} lg={24}>
        <div className="sale-ui-history-item">
          <div className="sale-ui-history-item__content">
            <div className="sale-ui-history-item__content-info">
              <div
                className="row-time"
                onClick={() => {
                  props.handleWhenClickStatus(item.status, item);
                }}
              >
                <div className="sale-ui-history-item__date">
                  {moment(
                    item?.lastModificationTime || item?.creationTime
                  ).format("YYYY年MM月DD日")}
                </div>
                <div className="sale-ui-history-item__time">
                  {moment(
                    item?.lastModificationTime || item?.creationTime
                  ).format("HH:mm")}
                </div>
              </div>
              <div
                className="row-name"
                onClick={() => {
                  props.handleWhenClickStatus(item.status, item);
                }}
              >
                <div className="sale-ui-history-item__name">
                  <div className="sale-ui-history-item__name-title three-dot">
                    {item?.title}
                  </div>
                  <div className="sale-ui-history-item__name-maker">
                    <div className="three-dot">{item?.maker}</div>
                    <div>
                      {item?.yearOfManufacture
                        ? item?.yearOfManufacture >= 1980
                          ? `${item?.yearOfManufacture}年式`
                          : "1980年以前"
                        : "年式不明"}
                    </div>
                  </div>
                </div>
                <div className="sale-ui-history-item__status"></div>
              </div>

              <div
                className="sale-ui-history-item__price"
                onClick={() => {
                  props.handleWhenClickStatus(item.status, item);
                }}
              >
                <div className="bold color-primary box-price">
                  <span>{translate("product.detail.price.asking")}: </span>
                  <div className="box-price__content">
                    <span className="text-caption-2 ">¥</span>
                    <NumberFormat
                      value={item?.price}
                      displayType="text"
                      thousandSeparator
                    />
                  </div>
                </div>
              </div>
              <div
                className="sale-ui-history-item__price"
                onClick={() => {
                  props.handleWhenClickStatus(item.status, item);
                }}
              >
                <div className="bold color-primary box-price">
                  <span>{translate("common.commission")}: </span>
                  <div className="box-price__content">
                    <span className="text-caption-2 ">¥</span>
                    <NumberFormat
                      value={item?.totalCommissionAmountOnTax}
                      displayType="text"
                      thousandSeparator
                    />
                  </div>
                </div>
              </div>

              <div
                className="sale-ui-history-item__price"
                onClick={() => {
                  props.handleWhenClickStatus(item.status, item);
                }}
              >
                <div className="bold color-primary box-price">
                  <div className="box-price__content">
                    <span className="internal-tax">
                      (うち、消費税{" "}
                      <NumberFormat
                        value={item?.totalConsumptionTaxAmount}
                        displayType="text"
                        thousandSeparator
                      />
                      円)
                    </span>
                  </div>
                </div>
              </div>

              <div className="sale-ui-history-item__price price-row-end">
                <div
                  className="bold color-primary total-price"
                  onClick={() => {
                    props.handleWhenClickStatus(item.status, item);
                  }}
                >
                  <div className="box-price">
                    <span>{translate("product.confirm.total.amount")}: </span>
                    <div className="box-price__content">
                      <span className="text-caption-2 ">¥</span>
                      <NumberFormat
                        value={item?.totalAmount}
                        displayType="text"
                        thousandSeparator
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sale-ui-history-item__content-status"
              onClick={() => {
                props.handleWhenClickStatus(item.status, item);
              }}
            >
              <div className="sale-ui-history-item__content-status__tag">
                <div>
                  <AppTag
                    icon={getIcon(item?.status)}
                    color={getColor(item?.status)}
                    backgroundColor={getBackground(item?.status)}
                    label={translate(`sale.history.status.${item?.status}`)}
                  />
                </div>
                <div>
                  <Mobile>
                    {(item.status === ProductTransactionStatus.IN_REQUEST ||
                      item.status === ProductTransactionStatus.IN_CONTACT ||
                      item.status === ProductTransactionStatus.IN_CONFIRM ||
                      item.status === ProductTransactionStatus.IN_PURCHASE ||
                      item.status ===
                        ProductTransactionStatus.PAYMENT_COMPLETE ||
                      item.buyerId === 0) && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Popover
                          visible={visible}
                          onVisibleChange={onVisibleChange}
                          content={() => renderAction()}
                          trigger="click"
                          placement="bottomRight"
                        >
                          <div className="sale-ui-history-item__action">
                            <Action />
                          </div>
                        </Popover>
                      </div>
                    )}
                  </Mobile>
                </div>
              </div>
              <div className="sale-ui-history-item__content-status__action">
                <Desktop>{renderAction()}</Desktop>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};

export default SaleHistoryItem;

SaleHistoryItem.defaultProps = {};
