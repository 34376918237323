import { Input } from "antd";
import React, { FC, memo, useEffect, useRef, useState } from "react";
import "./style.scss";
import { get } from "lodash";
import AppBottomSheet from "@components/AppBottomSheet";
import { ReactComponent as ArrowLeft } from "@components/Icons/arrow-left.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/configureStore";
import { setKeyword, setStatusFilter } from "src/store/Product-Filter";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { PATH_NAME } from "src/configs";
import Translate from '@components/Icons/translate';
import { setShowTranslate } from '@store/State';
import SearchFilterForm from "../search-filter";

interface SearchFormProps {
  placeholder?: string;
}

const AppSearchForm: FC<SearchFormProps> = memo(({ ...props }) => {
  const statusFilter = useSelector(
    (state: RootState) => state.ProductFilterReducer.statusFilter
  );
  const windowHeight = useRef<number>(window.innerHeight);
  const input = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { t: translate } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const keyword = useSelector(
    (state: RootState) => state.ProductFilterReducer.keyword
  );
  const showTranslate = useSelector(
    (state: RootState) => state.AppStateReducer.showTranslate
  );
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });

  function handleFilterDesktop() {
    const searchButton = document.getElementById("searchButton");
    if (searchButton) {
      searchButton.click();
    }
  }

  function handleFilter(e: any) {
    if (keyword !== get(e, "target.value")) {
      const value = get(e, "target.value") || "";
      const keyword = value.replace(/  +/g, " ");
      dispatch(setKeyword(keyword));
      if (!location.pathname.includes(PATH_NAME.DASHBOARD)) {
        navigate(`/dashboard?filter=true&keyword=${keyword}`);
      } else {
        dispatch(setKeyword(value !== "" ? keyword : get(e, "target.value")));
      }
      if (isDesktop) {
        handleFilterDesktop();
      }
    }
  }

  useEffect(() => {
    if (!statusFilter) {
      setSearchValue("");
    }
  }, [statusFilter]);

  useEffect(() => {
    setSearchValue(keyword || "");
  }, [keyword]);

  function handleKeyPress(e: any) {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  function handleKeyboardShow(e: Event) {
    if (window.innerHeight === windowHeight.current) {
      if (input.current) {
        (input.current as any).blur();
      }
    }
  }

  useEffect(() => {
    windowHeight.current = window.innerHeight;
    window.addEventListener("resize", handleKeyboardShow);
    return () => {
      window.removeEventListener("resize", handleKeyboardShow);
    };
  }, []);

  return (
    <>
      <div className="search-form-container">
        {statusFilter && (
          <div
            className="icon-back"
            onClick={() => {
              // dispatch(setStatusFilter({ status: false }));
              navigate("/dashboard");
            }}
          >
            <ArrowLeft />
          </div>
        )}
        <Input
          ref={input}
          onBlur={(e) => {
            handleFilter(e);
          }}
          onKeyDown={(e) => handleKeyPress(e)}
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          placeholder={isDesktop ? "メーカー商品名モデル、その他等で検索" : ""}
          className="input-container text-body-3"
          prefix={
            <div style={{ display: "flex" }}>
              <img src="/assets/icons/search.svg" alt="" />
            </div>
          }
        />
        <div
          className="icon-filter"
          onClick={() => {
            setShowFilter(true);
            // dispatch(setStatusFilter({ status: true }));
          }}
        >
          <img src="/assets/icons/filter.svg" alt="" />
          <div className="text">絞り込み</div>
        </div>
        {
          !(window as any).ReactNativeWebView && (
            <div
              className="icon-filter"
              onClick={() => {
                const isOnline = window.navigator.onLine;
                if (!isOnline) {
                  navigate('/network-error');
                  return ;
                }
                dispatch(setShowTranslate(!showTranslate));
              }}
              style={{
                color: showTranslate ? "#FF671E" : "#FCFCFC",
                background: showTranslate ? "#FCFCFC" : "transparent",
                borderRadius: '8px',
                padding: showTranslate ? '0 10px' : '0px',
              }}
            >
              <Translate active={showTranslate} />
              <div className="text">言語</div>
            </div>
          )
        }
      </div>

      {showFilter && (
        <AppBottomSheet
          title={translate("search.filter.title")}
          visible={showFilter}
          onClose={() => {
            setShowFilter(false);
            // dispatch(setStatusFilter({ status: false }));
          }}
          body={
            <SearchFilterForm
              onCancel={() => {
                setShowFilter(false);
              }}
              onOk={() => {
                setShowFilter(false);
                dispatch(setStatusFilter({ status: true }));
              }}
            />
          }
          height="100%"
        ></AppBottomSheet>
      )}
    </>
  );
});

export default AppSearchForm;
