import React, { FC, useEffect, useMemo, useState } from "react";

import { useMediaQuery } from "react-responsive";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import "./style.scss";
import {
  EyeOutlined,
  HeartOutlined,
  HeartFilled,
  HistoryOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { Col, Row, Skeleton } from "antd";
import { DATE_FORMAT, PATH_NAME } from "src/configs";
import {
  clearProduct,
  getProduct as getProductAction,
  setFavoriteCount,
} from "@store/Product";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@store/configureStore";
import {
  ProductItem,
  ProductPublicStatus,
  ProductTransactionStatus,
} from "@models/product.model";
import AppRow from "@components/AppRow";
import AppButton from "@components/AppButton";
import AppModal from "@components/AppModal";
import { ReactComponent as Success } from "@components/Icons/success.svg";
import AppShowMedia from "src/components/AppShowMedia";
import { ReactComponent as ArrowLeft } from "@components/Icons/arrow-left-active.svg";
import ProductService from "@service/API/Product";
import { setAppLoading, setError } from "@store/State";
import classNames from "classnames";
import { get, isEmpty } from "lodash";
import { Mobile, Desktop } from "src/layouts/break-point";
import { ReactComponent as Trash } from "@components/Icons/trash-1.svg";
import moment from "moment";
import { clearRoomIds, setRoomId } from "@store/Chat";
import UserService from "@service/API/User";
import { ReactComponent as Checked } from "@components/Icons/icon-checked.svg";
import { ReactComponent as Uncheck } from "@components/Icons/icon-uncheck.svg";
import useScrollTop from "src/hooks/useScrollTop";
import AppPullToRefresh from "src/layouts/pull-refresh/AppPullToRefresh";
import AppHelmet from '@components/AppHelmet';
import { buildLink, getIdError, getOS, handleOpenApp, paramsDisableModal } from "src/helper";
import { injectHandleOk } from "src/App";
import { setKeywords, setMetaTitle } from "src/store/Global";
import { ReactComponent as Warning } from "@components/Icons/warning.svg";
import StorageService from "../../services/Storage";
import LinkIcon from "../LinkIcon";

const ProductDetailScreen: FC = ({ ...props }) => {
  const isSmallMobile = useMediaQuery({ query: '(max-width: 500px)' });
  useScrollTop();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t: translate } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams, setSearchParams] = useSearchParams();

  const href = `haraseikouapp://home/productDetail${id}`;
  const disableModal = searchParams.get("disableModal");

  const [isLiked, setLiked] = useState(false);
  const [skeletonVisible, setSkeletonVisible] = useState<boolean>(false);
  const currentUser = useSelector(
    (state: RootState) => state.UserReducer.userInfo
  );

  const product = useSelector(
    (state: RootState) => state.ProductReducer.productDetail
  );
  const user = useSelector((state: RootState) => state.UserReducer.userInfo);
  const isMyProduct = product?.userId === user?.id;
  const isBuyer = product?.buyerId === user?.id;
  const isLogined = useSelector(
    (state: RootState) => state.UserReducer.isLogin
  );
  const [confirmLogin, setConfirmLogin] = useState<boolean>(false);
  const [confirmLoginType, setConfirmLoginType] = useState<"CONFIRM-BUY" | "INQUIRY">("CONFIRM-BUY");

  const [warningModalDeleteVisible, setWarningModalDeleteVisible] =
    useState<boolean>(false);

  const [successModalDeleteVisible, setSuccessModalDeleteVisible] =
    useState<boolean>(false);

  const [warningPublishModalVisible, setWarningPublishModalVisible] =
    useState<boolean>(false);
  const [successPublishModalVisible, setSuccessPublishModalVisible] =
    useState<boolean>(false);
  const [warningUnPublishModalVisible, setWarningUnPublishVisible] =
    useState<boolean>(false);
  const [successUnPublishModalVisible, setSuccessUnPublishModalVisible] =
    useState<boolean>(false);
  const [showApp, setShowApp] = useState<boolean>(false);
  const [isHasBankInfo, setIsHasBankInfo] = useState<boolean>(false);
  const [showModalBank, setShowModalBank] = useState<boolean>(false);
  const [loadingLike, setLoadingLike] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string>("");

  const currentPathname = useSelector(
    (state: RootState) => state.GlobalReducer.currentPathname
  );

  const location = useLocation();

  useEffect(() => {
    if (
      !disableModal &&
      (!currentPathname?.length || currentPathname.length === 1) &&
      !StorageService.getDevice()
    ) {
      handleOpenApp(href, setShowApp);
    }
    if (!disableModal) {
      navigate(paramsDisableModal(), { replace: true });
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (isLogined) {
        try {
          const response = await UserService.getBankAccountStatus();
          setIsHasBankInfo(response.data.result);
        } catch (e) {
          console.log(e);
        }
      }
    })();
  }, []);


  function handleBack() {
    if (get(location, "state.backUrl")) {
      navigate((location.state as any).backUrl, {
        replace: true,
        state: { routeState: -2 },
      });
      return;
    }

    if (!currentPathname?.length || currentPathname.length === 1) {
      navigate("/");
    } else {
      navigate(-1);
    }
  }

  const getProduct = async () => {
    setSkeletonVisible(true);
    try {
      await dispatch(getProductAction(`${id}`));
    } catch (e) {
      console.log(e);
    } finally {
      setSkeletonVisible(false);
    }
  };

  useEffect(() => {
    if (id) {
      getProduct();
    }
  }, [id]);

  function handleLike() {
    setLoadingLike(true);
    ProductService.updateLike({ id: Number(product.id), favorite: !product.canRemoveFromFavorite })
      .then((res) => {
        setLoadingLike(false);
        setLiked((like) => {
          dispatch(
            setFavoriteCount({
              count: !like
                ? product.favoriteCount + 1
                : product.favoriteCount - 1,
              canRemoveFromFavorite: !like,
            })
          );
          return !like;
        });
      })
      .catch((err) => {
        setLoadingLike(false);
      });
  }

  useEffect(() => {
    if ( !product?.title ) return;
    const keywordsContent = `${product.title},${product.keyword}`;
    dispatch(setKeywords(keywordsContent));
  }, [product?.title]);

  useEffect(() => {
    setLiked(product?.canRemoveFromFavorite || false);
  }, [product]);

  const productOverView = useMemo(
    () => [
      // {
      //   label: "common.commission",
      //   value: (
      //     <div className="price bold">
      //       <span className="text-body-2 text-caption-2 color-secondary">
      //         ¥
      //       </span>
      //       <NumberFormat
      //         className="text-body-4 color-secondary"
      //         value={Math.round(
      //           Number(
      //             (product?.commissionAmount as number) +
      //               (product?.consumptionTaxAmount as number) || 0
      //           )
      //         )}
      //         displayType="text"
      //         thousandSeparator
      //       />
      //       <span className="text-normal">（内税）</span>
      //     </div>
      //   ),
      // },
      // {
      //   label: "product.detail.category",
      //   value: product.categoryName
      //     ?.split(">")
      //     .slice(1, product.categoryName.split(">").length)
      //     .join(">"),
      // },
      // { label: "product.detail.maker", value: product?.maker },
      // { label: "product.detail.title", value: product?.title },
      // {
      //   label: "product.detail.year",
      //   value: product?.yearOfManufacture
      //     ? product.yearOfManufacture >= 1980
      //       ? product.yearOfManufacture
      //       : "1980年以前"
      //     : "年式不明",
      // },
      // { label: "product.detail.keyword", value: product?.keyword },
      // { label: "product.detail.comment", value: product?.comment },
      {
        label: "product.detail.transport",
        value: product?.carryingCost === 1 ? "（販売者）負担" : "（購入者）負担",
      },
      {
        label: "product.detail.posting.end.date",
        value: product?.endDate
          ? `${moment(product?.endDate, DATE_FORMAT).format("YYYY年MM月DD日")}`
          // : "年式不明",
          : "未公開",
        isCheckbox: false,
      },
      {
        label: "product.detail.location",
        value: `${product?.city} ${product?.district}`,
      },
      // { label: "product.detail.comment", value: product?.comment },
    ],
    [id, product]
  );

  const myProductOverView = useMemo(
    () => [
      // {
      //   label: "product.detail.upload.youtube",
      //   value: (
      //     <div className="box-checked color-body">
      //       {product.isPostVideoToYoutube ? <Checked /> : <Uncheck />}
      //     </div>
      //   ),
      //   isCheckbox: true,
      //   isHidden:
      //     product?.productMedias?.filter((item) => item.type === 2).length ===
      //     0,
      // },
      {
        label: "product.detail.category",
        value: product.categoryName
          ?.split(">")
          .slice(1, product.categoryName.split(">").length)
          .join(">"),
        isCheckbox: false,
      },
      { label: "product.detail.maker", value: product?.maker },
      { label: "product.detail.title", value: product?.title },
      {
        label: "product.detail.year",
        value: product?.yearOfManufacture
          ? product?.yearOfManufacture >= 1980
            ? product?.yearOfManufacture
            : "1980年以前"
          : "年式不明",
        isCheckbox: false,
      },
      {
        label: "product.detail.keyword",
        value: product?.keyword,
        isCheckbox: false,
      },
      {
        label: "product.detail.comment",
        value: product?.comment,
        isCheckbox: false,
      },
      {
        label: "product.detail.location",
        value: `${product?.city} ${product?.district}`,
        isCheckbox: false,
      },
      {
        label: "希望価格",
        value: (
          <div className="price bold">
            <span className="text-body-2 text-caption-2 color-secondary">
              ¥
            </span>
            <NumberFormat
              className="text-body-4 color-secondary"
              value={product.price}
              displayType="text"
              thousandSeparator
            />
          </div>
        ),
        isCheckbox: false,
      },
      {
        label: "common.commission",
        value: (
          <div className="price bold">
            <span className="text-body-2 text-caption-2 color-secondary">
              ¥
            </span>
            <NumberFormat
              className="text-body-4 color-secondary"
              value={Number(
                (product?.commissionAmount as number) +
                  (product?.consumptionTaxAmount as number) || 0
              )}
              displayType="text"
              thousandSeparator
            />
            <span className="text-normal">（内税）</span>
          </div>
        ),
        isCheckbox: false,
      },
      {
        label: "product.detail.posting.end.date",
        value: product?.endDate
          ? `${moment(product?.endDate, DATE_FORMAT).format("YYYY年MM月DD日")}`
          // : "年式不明",
          : "未公開",
        isCheckbox: false,
      },
      {
        label: "product.detail.visit.online",
        value: (
          <div className="box-checked">
            {product.isAllowOnlineTours ? <Checked /> : <Uncheck />}
          </div>
        ),
        isCheckbox: true,
      },
      {
        label: "product.detail.transport",
        value: product?.carryingCost === 1 ? "販売者" : "購入者",
        isCheckbox: false,
      },
      // {
      //   label: "product.detail.request.quotation",
      //   value: (
      //     <div className="box-checked">
      //       {product.isNeedTakeBackQuote ? <Checked /> : <Uncheck />}
      //     </div>
      //   ),
      //   isCheckbox: true,
      // },
      {
        label: "product.detail.estimation.date",
        value: `${moment(product?.estimationDate, DATE_FORMAT).format("YYYY年MM月DD日")}`,
        isHidden: !product?.isNeedTakeBackQuote,
        isCheckbox: false,
      },
    ],
    [id, product]
  );

  const handleDeleteProduct = async () => {
    setWarningModalDeleteVisible(false);
    dispatch(setAppLoading(true));
    try {
      await ProductService.deleteProduct(`${id}`);
      setSuccessModalDeleteVisible(true);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setAppLoading(false));
    }
  };

  const handlePublishProduct = async () => {
    dispatch(setAppLoading(true));
    setWarningPublishModalVisible(false);
    try {
      await ProductService.updateStatusProductProduct({
        productId: Number(id),
        status: ProductPublicStatus.Published,
        userToken: product.canPublish ? product.userToken : "",
      });
      setSuccessPublishModalVisible(true);
      getProduct();
    } catch (e) {
      if (getIdError(e) === "M0178" || getIdError(e) === "M0091") {
        injectHandleOk(() => getProduct());
      }
    } finally {
      dispatch(setAppLoading(false));
    }
  };

  const handleUnPublish = async () => {
    dispatch(setAppLoading(true));
    setWarningUnPublishVisible(false);
    try {
      await ProductService.unPublishProduct(id as string);
      setSuccessUnPublishModalVisible(true);
      getProduct();
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setAppLoading(false));
    }
  };

  const handleGoToChat = async () => {
    // navigate("/dashboard");
    // // return;
    if (!UserService.isAuthenticated()) {
      setConfirmLogin(true);
      return;
    }

    if (!window.navigator.onLine) {
      dispatch(clearRoomIds([]));
      navigate("/network-error");
    } else {
      dispatch(setRoomId(`${product.id}${user?.id}${product.userId}`));
    }
    //
    // if (roomId) {
    //   const roomIndex = await ChatService.getDocumentIndex(
    //     Collection.ROOMS,
    //     "roomId",
    //     roomId
    //   );
    //   await ChatService.leaveRoom(
    //     roomIndex as string,
    //     currentUser?.id as number
    //   );
    // }

    // dispatch(setRoomId(`${product.id}${user?.id}${product.userId}`));
  };

  const handlePublicProduct = () => {
    if (isHasBankInfo) {
      setWarningPublishModalVisible(true);
    } else setShowModalBank(true);
  };

  const EndDaysBody = ({ item }: { item: ProductItem | undefined }) => {
    if ( item === undefined ) return <div className="text-days"></div>;
    const publicDate = moment(item?.publicDate);
    const nowDate = moment();
    const elapsedDays = nowDate.diff(publicDate, 'days');
    if ( elapsedDays === 0 ) return <div className="text-days">本日登録</div>;
    if (Number.isNaN(elapsedDays)) return <div className="text-days">ー</div>;
    return <div className="text-days">{elapsedDays}日前</div>;
  };

  const MultiLineBody = ({ body }: { body: string }) => {
    if (body === undefined || body === null) return <div></div>;
    const texts = body.split(/\n/g).map((item, index) => 
        <React.Fragment key={index}>
          {item}
          <br />
        </React.Fragment>
    );
    return <div>{texts}</div>;
  };

  const renderButtons = () => (
    <>
      {isMyProduct && product.status === ProductPublicStatus.Unpublish && (
        <>
          <Col xs={12} md={12}>
            <AppButton
              buttontype="secondary"
              onClick={() => navigate(`${PATH_NAME.EDIT_PRODUCT}?id=${id}`)}
            >
              {translate("editProduct.edit")}{""}
            </AppButton>
          </Col>

          <Col xs={12}>
            <AppButton
              buttontype="primary"
              onClick={handlePublicProduct}
            >
              {translate("public.product.public")}
            </AppButton>
          </Col>
        </>
      )}

      {isMyProduct &&
        product.status === ProductPublicStatus.Published &&
        (!product.orderStatus ||
          product.orderStatus === ProductTransactionStatus.CANCEL) && (
          <>
            <Col xs={!product.canPublish ? 12 : 24}>
              <AppButton
                buttontype="secondary"
                onClick={() => navigate(`${PATH_NAME.EDIT_PRODUCT}?id=${id}`)}
              >
                {translate("editProduct.edit")}
              </AppButton>
            </Col>

            {!product.canPublish && (
              <Col xs={12}>
                <AppButton
                  buttontype="primary"
                  onClick={() => setWarningUnPublishVisible(true)}
                >
                  商品の公開を停止
                </AppButton>
              </Col>
            )}
          </>
        )}

      {isMyProduct &&
        product.status === ProductPublicStatus.Published &&
        product.orderStatus &&
        (product.orderStatus === ProductTransactionStatus.IN_PURCHASE ||
          product.orderStatus === ProductTransactionStatus.IN_REQUEST ||
          product.orderStatus === ProductTransactionStatus.IN_CONTACT ||
          product.orderStatus === ProductTransactionStatus.IN_CONFIRM) && (
          <Col xs={24}>
            <AppButton
              buttontype="primary"
              onClick={() => navigate(`${PATH_NAME.EDIT_PRODUCT}?id=${id}`)}
            >
              {translate("editProduct.edit")}
            </AppButton>
          </Col>
        )}

      {!isMyProduct && (
        <>
          {!product.orderStatus && (
            <Col xs={12}>
              <AppButton
                buttontype="primary"
                onClick={() => {
                  if (!isLogined) {
                    setConfirmLoginType("CONFIRM-BUY");
                    setConfirmLogin(true);
                    return;
                  }
                  navigate(`/product/${ id }/contact`);
                }}
              >
                {translate("editProduct.confirmBuy")}
              </AppButton>
            </Col>
          )}
          {(product.orderStatus && !isBuyer) && (
            <Col xs={12}>
              <AppButton
                buttontype="primary"
                onClick={() =>
                  dispatch(
                    setError({
                      message: "negotiating.description",
                      title: translate("editProduct.negotiating.title"),
                      icon: <Warning />
                    })
                  )
                }
              >
                {translate("editProduct.negotiating")}
              </AppButton>
            </Col>
          )}
          {(product.orderStatus && isBuyer) && (
            <Col xs={12}>
              <AppButton
                buttontype="primary"
                onClick={() => navigate(`/my-page/purchase-history/${product.transactionId}`)}
              >
                購入履歴
              </AppButton>
            </Col>
          )}
          <Col xs={12}>
            <AppButton buttontype="secondary" onClick={() => {
              setConfirmLoginType("INQUIRY");
              handleGoToChat();
            }}>
              {translate("editProduct.inquiry")}
            </AppButton>
          </Col>
        </>
      )}
    </>
  );

  useEffect(() => {
    if (product) {
      dispatch(
        setMetaTitle(
          `マシプラ ${
            !isEmpty(product)
              ? `| ${ProductService.getCategoryNameFromBE(
                  product.categoryName
                )} ${product.maker} ${product.title} ${
                  product?.yearOfManufacture
                    ? product?.yearOfManufacture >= 1980
                      ? `${product.yearOfManufacture}`
                      : "1980年以前"
                    : "年式不明"
                } ${product.price}`
              : ""
          }`
        )
      );
    }
    return () => {
      dispatch(setMetaTitle(""));
    };
  }, [product]);

  useEffect(
    () => () => {
      dispatch(clearProduct());
    },
    []
  );

  const onClickLink = () => {
    console.log('test onclick link');
  };

  return (
    <>
      <AppHelmet content={product?.comment} maxLength={70} />
      <div
        className="product-detail-container"
        style={{
          paddingBottom:
            !product.orderStatus ||
            product.orderStatus === ProductTransactionStatus.IN_PURCHASE ||
            product.orderStatus === ProductTransactionStatus.IN_REQUEST ||
            product.orderStatus === ProductTransactionStatus.IN_CONTACT ||
            product.orderStatus === ProductTransactionStatus.IN_CONFIRM
              ? "72px"
              : "0",
        }}
      >
        <AppModal
          visible={confirmLogin}
          title="この先の機能はログインが必要となります。"
          content=""
          okText="ログイン"
          cancelText="閉じる"
          onOk={() => navigate(`${PATH_NAME.LOGIN}?returnUrl=/product-detail/${product.id}`)}
          onCancel={() => {
            setConfirmLogin(false);
          }}
        />
        <AppModal
          visible={showApp}
          title="このページを“マシプラ”で開きますか？"
          cancelText="キャンセル"
          onCancel={() => setShowApp(false)}
          onOk={() => {
            setShowApp(false);
            buildLink(href);
          }}
          okText={
            getOS() === "Android" ? (
              <a href={href}>
                <span style={{ color: "#fff" }}>あける</span>
              </a>
            ) : (
              <span style={{ color: "#fff" }}>あける</span>
            )
          }
        />
        <AppModal
          visible={warningPublishModalVisible}
          title={translate("editProduct.warningPublishModal.title")}
          content={translate("editProduct.warningPublishModal.content")}
          okText="はい"
          cancelText="いいえ"
          onCancel={() => setWarningPublishModalVisible(false)}
          onOk={handlePublishProduct}
        />

        <AppModal
          icon={<Success />}
          visible={successPublishModalVisible}
          title="登録した商品が公開されました。"
          content={moment().format("YYYY/MM/DD HH:mm")}
          okText={translate("editProduct.successPublishModal.okText")}
          onOk={() => setSuccessPublishModalVisible(false)}
        />

        <AppModal
          visible={warningUnPublishModalVisible}
          title="この商品を未公開します。よろしいでしょうか？"
          content="未公開設定をした商品はマシプラの販売商品一覧には表示されません。"
          okText="はい"
          cancelText="いいえ"
          onCancel={() => setWarningUnPublishVisible(false)}
          onOk={handleUnPublish}
        />

        <AppModal
          icon={<Success />}
          visible={successUnPublishModalVisible}
          title="商品が未公開されました。"
          content={moment().format("YYYY/MM/DD HH:mm")}
          okText="閉じる"
          onOk={() => setSuccessUnPublishModalVisible(false)}
        />

        <AppModal
          visible={warningModalDeleteVisible}
          title={translate("product.detail.warningModalDelete.title")}
          content={translate("product.detail.warningModalDelete.content")}
          okText={translate("product.detail.warningModalDelete.okText")}
          cancelText={translate("product.detail.warningModalDelete.cancelText")}
          onCancel={() => setWarningModalDeleteVisible(false)}
          onOk={handleDeleteProduct}
        />

        <AppModal
          icon={<Success />}
          visible={successModalDeleteVisible}
          title={translate("product.detail.successModalDelete.title")}
          content={translate("product.detail.successModalDelete.content")}
          okText={translate("product.detail.successModalDelete.okText")}
          onOk={() => {
            setSuccessModalDeleteVisible(false);
            navigate(`/my-page/sales-history?tab=2`);
          }}
        />
        <AppModal
          visible={showModalBank}
          icon={<Warning />}
          title="口座情報を設定して下さい。"
          content="商品登録にはマシプラからお支払いを行うための銀行口座登録が必要となります。"
          okText="設定へ"
          cancelText="キャンセル"
          onCancel={() => setShowModalBank(false)}
          onOk={() => {
            navigate("/my-page/bank-account");
          }}
        />

        <Mobile>
        {skeletonVisible ? (
            <Row gutter={[32, 32]} justify="center" className="skeleton-product">
              <Col xs={24}>
                <Skeleton.Avatar
                  active
                  shape="square"
                  className="product-skeleton-avatar"
                />
              </Col>
              <Col xs={20}>
                <Skeleton.Avatar
                  active
                  shape="square"
                  className="product-skeleton-slider"
                />
              </Col>
              <Col xs={24}>
                <Skeleton active paragraph={{ rows: 15 }} />
              </Col>
            </Row>
          ) : (
            <AppPullToRefresh
              onRefresh={() => {
                getProduct();
              }}
            >
                <div className="title-bar">
                  <div className="headerName">
                        <span className="header-title">商品詳細</span>
                    </div>
                    <div className="button-back" onClick={handleBack}>
                      <ArrowLeft />
                    </div>
                </div>
              <div className="main-scroll">  
                <div className="header-container">

                  {/* {!isMyProduct &&
                    product?.orderStatus !== ProductTransactionStatus.DONE && (
                      <div
                        className={classNames(
                          "button-like",
                          { active: isLiked },
                          { disalbed: loadingLike }
                        )}
                        onClick={() => {
                          handleLike();
                        }}
                      >
                        {isLiked ? <HeartFilled /> : <HeartOutlined />}
                      </div>
                    )} */}
                    <div className="icon-on-image">
                    {isMyProduct && !product?.orderStatus && (
                    <div
                      className={classNames(
                        "button-like",
                        { active: isLiked },
                        { disalbed: loadingLike }
                        )}
                      onClick={() => setWarningModalDeleteVisible(true)}
                    >
                      <Trash />
                    </div>
                    )}
                    {product?.isAllowOnlineTours && (
                      <div className="online-view">
                        <img alt="" src="/assets/images/online_meet_icon.png" />
                      </div>
                    )}
                    </div>

                    <AppShowMedia productMedias={product?.productMedias} />
                </div>

                <div className="product-content">
                  <div className="content-info">
                    <div className="content-header">
                      <div>{product?.categoryName}</div>
                      <div><span className="text-days-left">
                          <HistoryOutlined />
                          <EndDaysBody item={product} />
                        </span></div>
                    </div>
                    <div className="product-name color-title text-body-1 bold">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="product-title">
                          {product?.title}
                        </div>
                      </div>
                    </div>
                    <div className="row-maker-view">
                        <div className="text-maker-name">
                          {product?.maker}
                        </div>
                        <div className="text-year">
                          {product?.yearOfManufacture
                            ? product?.yearOfManufacture >= 1980
                              ? `${product?.yearOfManufacture}年式`
                              : "1980年以前"
                            : "年式不明"}
                        </div>

                    </div>
                    <div className="row-icon-view">
                      <div className="view-like-container color-title text-body-4">
                        <EyeOutlined />
                        <span className="text-value">
                          {product?.viewCount > 999
                            ? "999+"
                            : product?.viewCount || 0}
                        </span>

                        <div
                            className={classNames(
                              "button-like",
                              { active: isLiked },
                              { disabled: loadingLike },
                            )}
                            onClick={() => {
                              handleLike();
                            }}
                          >
                          {isLiked ? <HeartFilled /> : <HeartOutlined />}
                        </div>

                        {/* <HeartOutlined /> */}
                        <span className="text-value">
                          {product?.favoriteCount > 999
                            ? "999+"
                            : product?.favoriteCount || 0}
                        </span>
                        {/* <span
                          className="text-days-left"
                        >
                          <HistoryOutlined />
                          <EndDaysBody item={product} />
                        </span> */}
                      </div>
                    </div>
                    <div className="row-price-view">

                    {product.orderStatus &&
                          product.orderStatus !==
                          ProductTransactionStatus.DONE && (
                            <div
                              style={{
                                color: "#FFFFFF",
                                backgroundColor: "#ff4d4f",
                                fontSize: "12px",
                                padding: "2px 20px",
                                marginLeft: "4px",
                                maxHeight: "30px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              取引中
                            </div>
                          )}
                        {product.orderStatus &&
                          product.orderStatus ===
                          ProductTransactionStatus.DONE && (
                            <div
                              style={{
                                color: "#FFFFFF",
                                backgroundColor: "#ff4d4f",
                                fontSize: "12px",
                                padding: "2px 20px",
                                marginLeft: "4px",
                                maxHeight: "30px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              取引完了
                            </div>
                          )}
                          {!isSmallMobile && 
                                <span style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>{translate('product.detail.productId')}: {product.id}</span>
                          }
                      <div className="price color-secondary text-body-2 bold">
                        <span className="text-caption-3">¥</span>
                        <NumberFormat
                          value={Number(product?.price || 0)}
                          displayType="text"
                          thousandSeparator
                        />
                        <br />
                        <span className="text-tax-value">（別途手数料:¥
                        <NumberFormat
                          value={Math.round(
                            Number(
                              (product?.commissionAmountOnTax as number) ||  0
                            )
                          )}
                          displayType="text"
                          thousandSeparator
                        />）</span>
                        </div>
                    </div>
                    {isSmallMobile && <div className="row-price-view" style={{ marginTop: 0 }}>
                          <span style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>{translate('product.detail.productId')}: {product.id}</span>
                    </div>}
                  </div>
                  <LinkIcon product={product} isMobile={false} />
                  <div className="content-overview">
                    {!isMyProduct &&
                      productOverView &&
                      productOverView.map(({ label, value }, index) => (
                        <AppRow
                          labelWidth="34%"
                          valueWidth="66%"
                          key={index}
                          label={translate(label)}
                          value={value}
                          // hasBorderBottom={index !== productOverView.length - 1}
                          hasBorderBottom={true}
                          />
                      ))}
                    <div className="product-comment-label">{translate("product.detail.comment")}</div>
                    <div className="product-comment"><MultiLineBody body={product?.comment}></MultiLineBody></div>                       
                    {/* <AppFieldset title={translate("product.detail.overview")}>
                      {!isMyProduct &&
                        productOverView &&
                        productOverView.map(({ label, value }, index) => (
                          <AppRow
                            labelWidth="34%"
                            valueWidth="66%"
                            key={index}
                            label={translate(label)}
                            value={value}
                            // hasBorderBottom={index !== productOverView.length - 1}
                          />
                        ))}
                      {isMyProduct &&
                        myProductOverView &&
                        myProductOverView.map(
                          ({ label, value, isHidden, isCheckbox }, index) => (
                            <AppRow
                              labelWidth={isCheckbox ? "96%" : "34%"}
                              valueWidth={isCheckbox ? "4%" : "66%"}
                              isCheckbox={isCheckbox}
                              key={index}
                              isHidden={isHidden}
                              label={translate(label)}
                              value={value}
                              hasBorderBottom={
                                index !== myProductOverView.length - 1
                              }
                            />
                          )
                        )}
                    </AppFieldset> */}
                  </div>
                </div>

                {/* {(!product.orderStatus ||
                product.orderStatus === ProductTransactionStatus.IN_PURCHASE ||
                product.orderStatus === ProductTransactionStatus.IN_REQUEST ||
                product.orderStatus === ProductTransactionStatus.IN_CONTACT ||
                product.orderStatus === ProductTransactionStatus.IN_CONFIRM ||
                product.orderStatus === ProductTransactionStatus.DONE) && ( */}
                <div className="button-container">
                  <Row gutter={[32, 32]} justify="center">
                    {renderButtons()}
                  </Row>
                </div>
                {/* )} */}
              </div>
            </AppPullToRefresh>
          )}
        </Mobile>

        <Desktop>
          {skeletonVisible ? (
            <Row
              gutter={[32, 32]}
              style={{ marginTop: "24px" }}
              className="skeleton-product"
            >
              <Col xs={14}>
                <Skeleton.Avatar
                  shape="square"
                  active
                  className="product-skeleton-avatar"
                ></Skeleton.Avatar>
              </Col>
              <Col xs={10}>
                <Skeleton paragraph={{ rows: 15 }} active />
                <Row gutter={[32, 32]} justify="center">
                  <Col xs={12} md={8}>
                    <Skeleton.Button active className="product-skeleton-button" />
                  </Col>

                  <Col xs={12} md={8}>
                    <Skeleton.Button active className="product-skeleton-button" />
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <div className="ui-product-detail-desktop">
              <Row gutter={[32, 32]}>
                <Col xs={14}>
                  <div className="video-container">
                    <div className="row-arrow-left"><ArrowLeft  onClick={handleBack} /></div>
                    
                    {/* {!isMyProduct &&
                      product?.orderStatus !== ProductTransactionStatus.DONE && (
                        <div
                          className={classNames(
                            "button-like",
                            { active: isLiked },
                            { disalbed: loadingLike }
                          )}
                          onClick={() => {
                            handleLike();
                          }}
                        >
                          {isLiked ? <HeartFilled /> : <HeartOutlined />}
                        </div>
                      )} */}
                    {product?.isAllowOnlineTours && (
                      <div className="online-view">
                        <img alt="" src="/assets/images/online_meet_icon.png" />
                      </div>
                    )}
                    <AppShowMedia productMedias={product?.productMedias} />
                  </div>
                </Col>
                <Col xs={10}>
                  <div style={{ padding: "0 16px" }}>
                    <div className="product-sns-link">
                      <LinkIcon product={product} isYoutubeHidden={true} onClickLink={onClickLink} isMobile={false} />
                    </div>
                    <div className="product-header">
                        <div className="product-category">
                          {product?.categoryName}
                        </div>
                        <div className="product-edit-days">
                          <HistoryOutlined />
                          <span
                            style={{ marginLeft: "8px" }}
                            className="text-value"
                          >
                          <EndDaysBody item={product} />
                          </span>
                        </div>
                    </div>


                    <div className="product-name color-title text-body-1 bold">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="product-title">
                          {product?.title}
                        </div>
                        {isMyProduct && !product?.orderStatus && (
                          <div
                            style={{
                              backgroundColor: "#EFF0F1",
                              color: "#666",
                              cursor: "pointer",
                              padding: "12px 28px",
                              borderRadius: "8px",
                              fontSize: "14px",
                              margin: "3px 0px 3px 4px",
                              width: "101px",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              maxHeight: "50px",
                            }}
                            onClick={() => setWarningModalDeleteVisible(true)}
                          >
                            削除
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="product-maker-row">
                      <div className="product-maker">
                        {product?.maker}
                      </div>
                      <div className="product-make-years">
                        {product?.yearOfManufacture
                            ? product?.yearOfManufacture >= 1980
                              ? `${product?.yearOfManufacture}年式`
                              : "1980年以前"
                            : "年式不明"}
                      </div>
                    </div>

                    <div className="view-like-container color-title text-body-4">
                      <EyeOutlined />
                      <span
                        style={{ marginRight: "16px", marginLeft: "8px" }}
                        className="text-value"
                      >
                      {product?.viewCount}
                      </span>

                      <div
                          className={classNames(
                            "button-like",
                            { active: isLiked },
                            { disalbed: loadingLike }
                          )}
                          onClick={() => {
                            handleLike();
                          }}
                        >
                        {isLiked ? <HeartFilled /> : <HeartOutlined />}
                      </div>
                      {/* <HeartOutlined /> */}
                      <span
                        style={{ marginLeft: "8px" }}
                        className="text-value"
                      >
                      {product?.favoriteCount}
                      </span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "16px",
                      }}
                      className="row-price-view"
                    >
                      {product.orderStatus &&
                          product.orderStatus !==
                          ProductTransactionStatus.DONE && (
                            <div
                              style={{
                                color: "#FFFFFF",
                                backgroundColor: "#ff4d4f",
                                fontSize: "12px",
                                padding: "2px 8px",
                                // borderRadius: "4px",
                                marginLeft: "4px",
                                maxHeight: "30px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              取引中
                            </div>
                          )}
                        {product.orderStatus &&
                          product.orderStatus ===
                          ProductTransactionStatus.DONE && (
                            <div
                              style={{
                                color: "#12B76A",
                                backgroundColor: "#D1FADF",
                                fontSize: "12px",
                                padding: "2px 8px",
                                borderRadius: "4px",
                                maxHeight: "30px",
                                marginLeft: "4px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              取引完了
                            </div>
                          )}
                          <span style={{ whiteSpace: 'nowrap', paddingLeft: '10px' }}>{translate('product.detail.productId')}: {product.id}</span>
                      <div className="price color-secondary text-body-2 bold">
                        <span className="text-body-3">¥</span>
                        <NumberFormat
                          value={Number(product?.price || 0)}
                          displayType="text"
                          thousandSeparator
                        />
                        <br />
                        <span className="text-tax-value">（別途手数料:¥
                        <NumberFormat
                          value={Math.round(
                            Number(
                              (product?.commissionAmountOnTax as number) || 0
                            )
                          )}
                          displayType="text"
                          thousandSeparator
                        />）</span>

                      </div>
                      {/* <div className="view-like-container color-title text-body-4">
                        <EyeOutlined />
                        <span
                          style={{ marginRight: "16px", marginLeft: "8px" }}
                          className="text-value"
                        >
                        {product?.viewCount}
                        </span> */}

                        {/* <div
                        className={classNames(
                          "button-like",
                          { active: isLiked },
                          { disalbed: loadingLike }
                        )}
                        onClick={() => {
                          handleLike();
                        }}
                      >
                        {isLiked ? <HeartFilled /> : <HeartOutlined />}
                      </div> */}
                        {/* <HeartOutlined />
                        <span
                          style={{ marginLeft: "8px" }}
                          className="text-value"
                        >
                        {product?.favoriteCount}
                        </span>
                        <HistoryOutlined />
                        <span
                          style={{ marginLeft: "8px" }}
                          className="text-value"
                        >
                          <EndDaysBody item={product} />
                        </span>
                      </div> */}
                    </div>
                  </div>

                  <LinkIcon product={product} isSnsHidden={true}/>
                  <div className="content-overview">
                    {!isMyProduct &&
                      productOverView &&
                      productOverView.map(({ label, value }, index) => (
                        <AppRow
                          labelWidth="34%"
                          valueWidth="66%"
                          key={index}
                          label={translate(label)}
                          value={value}
//                          hasBorderBottom={index !== productOverView.length - 1}
                          hasBorderBottom={true}
                        />
                      ))}
                    <div className="product-comment-label">{translate("product.detail.comment")}</div>
                    <div className="product-comment"><MultiLineBody body={product?.comment}></MultiLineBody></div>                       
                  </div>
{/* 
                  <AppFieldset title={translate("product.detail.overview")}>
                    {!isMyProduct &&
                      productOverView &&
                      productOverView.map(({ label, value }, index) => (
                        <AppRow
                          labelWidth="34%"
                          valueWidth="66%"
                          key={index}
                          label={translate(label)}
                          value={value}
                          hasBorderBottom={index !== productOverView.length - 1}
                        />
                      ))}
                    {isMyProduct &&
                      myProductOverView &&
                      myProductOverView.map(
                        ({ label, value, isHidden, isCheckbox }, index) => (
                          <AppRow
                            labelWidth={isCheckbox ? "96%" : "34%"}
                            valueWidth={isCheckbox ? "4%" : "66%"}
                            key={index}
                            isHidden={isHidden}
                            label={translate(label)}
                            value={value}
                            hasBorderBottom={
                              index !== myProductOverView.length - 1
                            }
                          />
                        )
                      )}
                  </AppFieldset> */}

                  <div>
                    <Row
                      gutter={[32, 32]}
                      justify="center"
                      style={{ marginLeft: 0, marginRight: 0 }}
                    >
                      {renderButtons()}
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Desktop>
      </div>
    </>
  );
};
export default ProductDetailScreen;