import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// eslint-disable-next-line import/newline-after-import
import { HubConnection } from "@microsoft/signalr";
interface AppState {
  loading: boolean;
  error: { icon?: any; message: string; title: string; action?: () => void };
  titleToolBar: string;
  isOffline: boolean;
  connectionSystem: null | HubConnection;
  showTranslate: boolean;
}

const initialState: AppState = {
  loading: false,
  error: {} as { icon?: any; message: string; title: string },
  titleToolBar: "",
  isOffline: false,
  connectionSystem: null,
  showTranslate: false
};

const slice = createSlice({
  name: "AppState",
  initialState,
  reducers: {
    setAppLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    clearError(state, action) {
      state.error = action.payload;
    },
    setTitle(state, action) {
      state.titleToolBar = action.payload;
    },
    setOffline(state, action) {
      state.isOffline = action.payload;
    },
    setConnectionSystem(state, action) {
      state.connectionSystem = action.payload;
    },
    setShowTranslate(state, action) {
      state.showTranslate = action.payload;
    }
  },
});

export const {
  setAppLoading,
  setError,
  clearError,
  setTitle,
  setOffline,
  setConnectionSystem,
  setShowTranslate
} = slice.actions;

export default slice.reducer;
