import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Room } from "@models/chat.model";

interface NoticeState {
  rooms: Array<Room>,
  currentState?: { count: number, position: number, page: number } | null;
  allRoom: Array<Room>
}

const initialState: NoticeState = { rooms: [], allRoom: [] };
const slice = createSlice({
  name: "Notice",
  initialState,
  reducers: {
    setNotices(state, action: PayloadAction<Array<Room>>) {
      state.rooms = action.payload;
    },
    setStatePosition: (state, { payload: currentScreenMobile }: { payload: { count: number, position: number, page: number } | null }) => {
      state.currentState = currentScreenMobile;
    },
    setAllRoom(state, action: PayloadAction<Array<Room>>) {
      state.allRoom = action.payload;
    },
  }
});

export const { setNotices, setStatePosition, setAllRoom  } = slice.actions;

export default slice.reducer;
